// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import UserApis from 'apis/allUsersApis';
// actions
import { UsersActionTypes } from 'actions/allUsersAction';

export function* handleGetUserInfo({ payload }) {
  try {
    const response = yield call(UserApis.getUserInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostUserInfo({ payload }) {
  try {
    const response = yield call(UserApis.postUserInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostUserSetupSecurity({ payload }) {
  try {
    const response = yield call(UserApis.postUserSetUpSecurity, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostContactInfo({ payload }) {
  try {
    const response = yield call(UserApis.postContactInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostVerifyContactInfo({ payload }) {
  try {
    const response = yield call(UserApis.postVerifyContactInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserAuthorization({ payload }) {
  try {
    const response = yield call(UserApis.getUserAuthorization, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetRibbonData({ payload }) {
  try {
    const response = yield call(UserApis.getRibbonData, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostDeleteUserAccountRequest({ payload }) {
  try {
    const response = yield call(UserApis.postDeleteUserAccountRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* allUserSaga() {
  yield all([
    yield takeEvery(UsersActionTypes.GET_USERS_INFO_REQUEST, handleGetUserInfo),
    yield takeEvery(
      UsersActionTypes.POST_USERS_INFO_REQUEST,
      handlePostUserInfo,
    ),
    yield takeEvery(
      UsersActionTypes.POST_USERS_SETUP_SECURITY,
      handlePostUserSetupSecurity,
    ),
    yield takeEvery(
      UsersActionTypes.POST_USERS_CONTACT_INFO_REQUEST,
      handlePostContactInfo,
    ),
    yield takeEvery(
      UsersActionTypes.POST_USERS_VERIFY_REQUEST,
      handlePostVerifyContactInfo,
    ),
    yield takeEvery(
      UsersActionTypes.GET_USERS_AUTHORIZATION,
      handleGetUserAuthorization,
    ),
    yield takeEvery(UsersActionTypes.GET_RIBBON_DATA, handleGetRibbonData),
    yield takeEvery(
      UsersActionTypes.POST_DELETE_USER_ACCOUNT_REQUEST,
      handlePostDeleteUserAccountRequest,
    ),
  ]);
}

export default allUserSaga;
