import { action } from 'typesafe-actions';

export const SSOActionTypes = {
  ACTIVE_SERVICES_REQUEST: '@@ssoAction/GET_ACTIVE_SERVICES_REQUEST',
  AVAILABLE_SERVICES_REQUEST: '@@ssoAction/GET_AVAILABLE_SERVICES_REQUEST',
  SERVICE_PROVIDERS_REQUEST: '@@ssoAction/SERVICE_PROVIDERS_REQUEST',
  SERVICE_CONFIGS_REQUEST: '@@ssoAction/SERVICE_CONFIGS_REQUEST',
  DELETE_SERVICE_REQUEST: '@@ssoAction/DELETE_SERVICE_REQUEST',
  GET_SERVICE_DOCS_REQUEST: '@@ssoAction/GET_SERVICE_DOCS_REQUEST',
  GET_SCIM_DOCS_REQUEST: '@@ssoAction/GET_SCIM_DOCS_REQUEST',
  GET_APPD_REQUEST: '@@ssoAction/GET_APPD_REQUEST',
  APP_REQUEST: '@@ssoAction/APP_REQUEST',
  GET_SERVICE_GROUPS: '@@ssoAction/GET_SERVICE_GROUPS',
};

export const activeServicesRequest = (data) =>
  action(SSOActionTypes.ACTIVE_SERVICES_REQUEST, data);
export const availableServicesRequest = (data) =>
  action(SSOActionTypes.AVAILABLE_SERVICES_REQUEST, data);
export const serviceProvidersRequest = (data) =>
  action(SSOActionTypes.SERVICE_PROVIDERS_REQUEST, data);
export const serviceConfigsRequest = (data) =>
  action(SSOActionTypes.SERVICE_CONFIGS_REQUEST, data);
export const deleteServiceRequest = (data) =>
  action(SSOActionTypes.DELETE_SERVICE_REQUEST, data);
export const getServiceDocsRequest = (data) =>
  action(SSOActionTypes.GET_SERVICE_DOCS_REQUEST, data);
export const getScimDocsRequest = (data) =>
  action(SSOActionTypes.GET_SCIM_DOCS_REQUEST, data);
export const getAppdRequest = (data) =>
  action(SSOActionTypes.GET_APPD_REQUEST, data);
export const appRequest = (data) => action(SSOActionTypes.APP_REQUEST, data);
export const getServiceGroups = (data) =>
  action(SSOActionTypes.GET_SERVICE_GROUPS, data);
