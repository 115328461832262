import Dispatch from './client';

const urls = {
  postNewReader: {
    url: `/administrator/support/new-reader`,
    method: 'post',
  },
  getDrivers: {
    url: `/administrator/workspace/downloads`,
    method: 'get',
  },
};

function api() {
  return {
    postNewReaderRequest: (data) =>
      Dispatch(urls.postNewReader, {}, data.payload),
    getDriversRequest: (data) => Dispatch(urls.getDrivers, {}, data.payload),
  };
}

export default api();
