import Dispatch from './client';

const urls = {
  addNewPolicy: {
    url: '/administrator/policies/create',
    method: 'post',
  },
  getallPolicies: {
    url: '/administrator/policies',
    method: 'get',
  },
  deletePolicy: {
    url: `/administrator/policies`,
    method: 'delete',
  },
  getDefaultPolicy: {
    url: '/administrator/policies/default',
    method: 'get',
  },
  putDefaultPolicy: {
    url: '/administrator/policies/default/set',
    method: 'put',
  },
  getUsers: {
    url: '/users/oneWorkspace',
    method: 'post',
  },
  getGroups: {
    url: '/administrator/groups/oneWorkspace',
    method: 'post',
  },
  getUsedPriorities: {
    url: '/administrator/policies/priority',
    method: 'get',
  },
  getPolicy: (policy_Id) => {
    return { url: `/administrator/policies/${policy_Id}`, method: 'get' };
  },
  editPolicy: (policy_Id) => {
    return { url: `/administrator/policies/${policy_Id}`, method: 'put' };
  },
};

function api() {
  return {
    postAddNewPolicy: (data) =>
      Dispatch(urls.addNewPolicy, data.params, data.payload),
    getAllPolicies: (data) =>
      Dispatch(urls.getallPolicies, data.params, data.payload),
    deletePolicy: (data) =>
      Dispatch(urls.deletePolicy, data.params, data.payload),
    getDefaultPolicy: (data) =>
      Dispatch(urls.getDefaultPolicy, data.params, data.payload),
    putDefaultPolicy: (data) =>
      Dispatch(urls.putDefaultPolicy, data.params, data.payload),
    getUsers: (data) => Dispatch(urls.getUsers, data.params, data.payload),
    getGroups: (data) => Dispatch(urls.getGroups, data.params, data.payload),
    getPolicy: (data) =>
      Dispatch(urls.getPolicy(data?.payload?._id), data.params, data.payload),
    editPolicy: (data) =>
      Dispatch(urls.editPolicy(data?.params?._id), data.params, data.payload),
    getUsedPriorities: (data) =>
      Dispatch(urls.getUsedPriorities, data.params, data.payload),
  };
}

export default api();
