// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import pinApis from 'apis/pinApis';
// actions
import { PinActionTypes } from 'actions/pinActions';

export function* handlePostActivateCardRequest({ payload }) {
  try {
    const response = yield call(pinApis.postActivateCardRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* pinSaga() {
  yield all([
    yield takeEvery(
      PinActionTypes.POST_ACTIVATE_CARD_REQUEST,
      handlePostActivateCardRequest,
    ),
  ]);
}

export default pinSaga;
