import { action } from 'typesafe-actions';

export const DownloadActionTypes = {
  POST_NEW_READER_REQUEST: '@@download/POST_NEW_READER_REQUEST',
  GET_DRIVERS_REQUEST: '@@download/GET_DRIVERS_REQUEST',
};

export const postNewReaderRequest = (data) =>
  action(DownloadActionTypes.POST_NEW_READER_REQUEST, data);
export const getDriversRequest = (data) =>
  action(DownloadActionTypes.GET_DRIVERS_REQUEST, data);
