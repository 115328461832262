// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import sessionApis from 'apis/sessionApis';
// actions
import { SessionActionTypes } from 'actions/sessionActions';

export function* handleGetMySessions({ payload }) {
  try {
    const response = yield call(sessionApis().getMySessions, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleTerminateMySession({ payload }) {
  try {
    const response = yield call(sessionApis().terminateMySession, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* sessionsSaga() {
  yield all([
    yield takeEvery(
      SessionActionTypes.GET_MY_SESSIONS_REQUEST,
      handleGetMySessions,
    ),
    yield takeEvery(
      SessionActionTypes.TERMINATE_MY_SESSION_REQUEST,
      handleTerminateMySession,
    ),
  ]);
}

export default sessionsSaga;
