import { action } from 'typesafe-actions';

export const AccessPolicyActionTypes = {
  GET_ACCESS_POLICY_REQUEST: '@@accessPolicy/GET_ACCESS_POLICY_REQUEST',
  POST_ACCESS_POLICY_REQUEST: '@@accessPolicy/POST_ACCESS_POLICY_REQUEST',
  PUT_ACCESS_POLICY_REQUEST: '@@accessPolicy/PUT_ACCESS_POLICY_REQUEST',
  DELETE_ACCESS_POLICY_REQUEST: '@@accessPolicy/DELETE_ACCESS_POLICY_REQUEST',
  GET_ALL_POLICY: '@@accessPolicy/GET_ALL_POLICY',
};

export const getAcessPolicyRequest = (data) =>
  action(AccessPolicyActionTypes.GET_ACCESS_POLICY_REQUEST, data);
export const postAccessPolicyRequest = (data) =>
  action(AccessPolicyActionTypes.POST_ACCESS_POLICY_REQUEST, data);
export const putAccessPolicyRequest = (data) =>
  action(AccessPolicyActionTypes.PUT_ACCESS_POLICY_REQUEST, data);
export const deleteAccessPolicyRequest = (data) =>
  action(AccessPolicyActionTypes.DELETE_ACCESS_POLICY_REQUEST, data);
export const getAllPolicyRequest = (data) =>
  action(AccessPolicyActionTypes.GET_ALL_POLICY, data);
