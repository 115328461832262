// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import trustDomainsApis from 'apis/trustDomainsApi';
// actions
import { TrustDomainsActionTypes } from 'actions/trustDomainsActions';

// export function* handleGet({ payload }) {
//   try {
//     const response = yield call(tokenPolicyApis.getTokenPolicy, payload);
//     payload.onSuccessCallback && payload.onSuccessCallback(response);
//   } catch (err) {
//     payload.onErrorCallback && payload.onErrorCallback();
//   }
// }
export function* handlePost({ payload }) {
  try {
    const response = yield call(trustDomainsApis.postTrustDomains, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDelete({ payload }) {
  try {
    const response = yield call(trustDomainsApis.deleteTrustDomains, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* tokenPolicySaga() {
  yield all([
    yield takeEvery(
      TrustDomainsActionTypes.POST_TRUST_DOMAINS_REQUEST,
      handlePost,
    ),
    yield takeEvery(
      TrustDomainsActionTypes.DELETE_TRUST_DOMAINS_REQUEST,
      handleDelete,
    ),
  ]);
}

export default tokenPolicySaga;
