import Dispatch from './client';

const urls = {
  getUser: {
    method: 'get',
    url: '/administrator/tokens/ids',
  },
  getOrganizationUnits: {
    method: 'get',
    url: '/administrator/organization-units',
  },
  getOrganizationUnitsNames: {
    method: 'get',
    url: '/administrator/organization-units/names',
  },
  getOrganizationUnit: (ouId) => ({
    method: 'get',
    url: `/administrator/organization-units/${ouId}`,
  }),
  createOrganizationUnit: {
    method: 'post',
    url: '/administrator/organization-units',
  },
  deleteOrganizationUnits: {
    method: 'delete',
    url: '/administrator/organization-units',
  },
  updateOrganizationUnits: (ouId) => ({
    method: 'put',
    url: `/administrator/organization-units/${ouId}`,
  }),
  getUserOrganizationUnits: (userId) => ({
    method: 'get',
    url: `/administrator/tokens/${userId}/organization-units`,
  }),
  deleteUserFromOrganizationUnits: (userId) => ({
    method: 'delete',
    url: `/administrator/tokens/${userId}/organization-units`,
  }),
  addUserToOrganizationUnits: (userId) => ({
    method: 'put',
    url: `/administrator/tokens/${userId}/organization-units/`,
  }),
};

function api() {
  return {
    getUser: (data) => Dispatch(urls.getUser, data.params, data.payload),
    getOrganizationUnits: (data) =>
      Dispatch(urls.getOrganizationUnits, data.params, data.payload),
    getOrganizationUnitsNames: (data) =>
      Dispatch(urls.getOrganizationUnitsNames, data.params, data.payload),
    getOrganizationUnit: (data) =>
      Dispatch(urls.getOrganizationUnit(data.OUId), data.params, data.payload),
    createOrganizationUnit: (data) =>
      Dispatch(urls.createOrganizationUnit, data.params, data.payload),
    deleteOrganizationUnits: (data) =>
      Dispatch(urls.deleteOrganizationUnits, data.params, data.payload),
    updateOrganizationUnits: (data) =>
      Dispatch(
        urls.updateOrganizationUnits(data.OUId),
        data.params,
        data.payload,
      ),
    getUserOrganizationUnits: (data) =>
      Dispatch(
        urls.getUserOrganizationUnits(data.userId),
        data.params,
        data.payload,
      ),
    deleteUserFromOrganizationUnits: (data) =>
      Dispatch(
        urls.deleteUserFromOrganizationUnits(data.userId),
        data.params,
        data.payload,
      ),
    addUserToOrganizationUnits: (data) =>
      Dispatch(
        urls.addUserToOrganizationUnits(data.userId),
        data.params,
        data.payload,
      ),
  };
}

export default api();
