import Dispatch from './client';

const urls = {
  administrators: (method) => ({
    url: '/administrator/workspace/administrators',
    method: method,
  }),
  deleteAdministrators: (method, _id) => ({
    url: '/administrator/workspace/administrators/' + _id,
    method: method,
  }),
  putProximity: {
    url: '/administrator/workspace/proximity',
    method: 'put',
  },
  getActiveUsers: {
    url: '/administrator/workspace/activeUsers',
    method: 'get',
  },
};

function api() {
  return {
    getAdministrators: (data) =>
      Dispatch(urls.administrators('get'), data.params, data.payload),
    postNewAdministrator: (data) =>
      Dispatch(urls.administrators('post'), data.params, data.payload),
    deleteAdministrator: (data) =>
      Dispatch(
        urls.deleteAdministrators(data.params.method, data.params._id),
        {},
        data.payload,
      ),
    putProximity: (data) =>
      Dispatch(urls.putProximity, data.params, data.payload),
    getActiveUsers: (data) =>
      Dispatch(urls.getActiveUsers, data.params, data.payload),
  };
}

export default api();
