// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import groupApis from 'apis/groupApis';
// actions
import { GroupActionTypes } from 'actions/groupsActions';

export function* handleGetGroupe({ payload }) {
  try {
    const response = yield call(groupApis.getGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserForAssignGroup({ payload }) {
  try {
    const response = yield call(groupApis.getUserForAssignInGroup, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostGroupe({ payload }) {
  try {
    const response = yield call(groupApis.postGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutUsersInGroup({ payload }) {
  try {
    const response = yield call(groupApis.putUsersInGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutAssignSecurityKey({ payload }) {
  try {
    const response = yield call(groupApis.putAssignSecurityKeyGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetOneGroupData({ payload }) {
  try {
    const response = yield call(groupApis.getOneGroupData, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutEditOneGroup({ payload }) {
  try {
    const response = yield call(groupApis.putEditOneGroup, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutEditOneGroupInfo({ payload }) {
  try {
    const response = yield call(groupApis.putEditOneGroupInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteOneGroup({ payload }) {
  try {
    const response = yield call(groupApis.deleteOneGroup, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutSuspendManyGroups({ payload }) {
  try {
    const response = yield call(groupApis.putSuspendManyGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAddGroupUsers({ payload }) {
  try {
    const response = yield call(groupApis.postAddGroupUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteGroupUsers({ payload }) {
  try {
    const response = yield call(groupApis.deleteGroupUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* groupSaga() {
  yield all([
    yield takeEvery(GroupActionTypes.GET_GROUP_REQUEST, handleGetGroupe),
    yield takeEvery(GroupActionTypes.POST_GROUP_REQUEST, handlePostGroupe),
    yield takeEvery(
      GroupActionTypes.GET_USERS_FOR_ASSIGN_GROUP,
      handleGetUserForAssignGroup,
    ),
    yield takeEvery(
      GroupActionTypes.PUT_USER_IN_GROUP_REQUEST,
      handlePutUsersInGroup,
    ),
    yield takeEvery(
      GroupActionTypes.PUT_ASSIGN_SECURITY_KEY,
      handlePutAssignSecurityKey,
    ),
    yield takeEvery(GroupActionTypes.GET_ONE_GROUP_DATA, handleGetOneGroupData),
    yield takeEvery(GroupActionTypes.PUT_EDIT_ONE_GROUP, handlePutEditOneGroup),
    yield takeEvery(
      GroupActionTypes.PUT_EDIT_ONE_GROUP_INFO,
      handlePutEditOneGroupInfo,
    ),
    yield takeEvery(GroupActionTypes.DELETE_ONE_GROUP, handleDeleteOneGroup),
    yield takeEvery(
      GroupActionTypes.PUT_SUSPEND_MANY_GROUPS,
      handlePutSuspendManyGroups,
    ),
    yield takeEvery(
      GroupActionTypes.POST_ADD_GROUP_USERS,
      handlePostAddGroupUsers,
    ),
    yield takeEvery(
      GroupActionTypes.DELETE_GROUP_USERS,
      handleDeleteGroupUsers,
    ),
  ]);
}

export default groupSaga;
