import Dispatch from './client';

const urls = {
  connectToAzureAD: {
    method: 'get',
    url: '/aad/connect',
  },
  getGroupsAzureAD: { method: 'post', url: '/administrator/aad/groups' },
  postSyncAzureAD: { method: 'post', url: '/administrator/aad/sync' },
  getToken: {
    method: 'get',
    url: '/administrator/aad/connect?state=autoObrClient',
  },

  postSyncIntegration: {
    method: 'post',
    url: '/administrator/tokens/serviceProvider',
  },
  getSyncAzureAD: {
    url: '/administrator/workspace/syncs',
    method: 'get',
  },
  getLastSyncAzureAD: (id) => ({
    url: `/administrator/workspace/syncs/${id}`,
    method: 'get',
  }),
  putLdapSync: {
    url: '/administrator/ad/import',
    method: 'put',
  },
  LdapConfigs: {
    url: '/administrator/ad/list',
    method: 'get',
  },
  deleteLdapConfig: (id) => ({
    url: `/administrator/ad/connection/${id}`,
    method: 'delete',
  }),
  TestLdapConnection: {
    url: '/administrator/ad/testConnection',
    method: 'post',
  },
  LdapOUs: {
    url: '/administrator/ad/ouList',
    method: 'post',
  },
  LdapDetails: {
    url: '/administrator/ad/details',
    method: 'post',
  },

  //Schedules
  LdapSchedules: {
    url: '/administrator/ad/schedules',
    method: 'get',
  },
  RunSchedule: (id) => ({
    url: `/administrator/ad/schedules/${id}`,
    method: 'get',
  }),
  deleteSchedule: (id) => ({
    url: `/administrator/ad/schedules/${id}`,
    method: 'delete',
  }),

  //Logs
  LdapLogs: {
    url: '/administrator/ad/logs',
    method: 'get',
  },
  deleteLog: (id) => ({
    url: `/administrator/ad/logs/${id}`,
    method: 'delete',
  }),
};

function api() {
  return {
    getConnectToAzureAD: (data) =>
      Dispatch(urls.connectToAzureAD, data.params, data.payload),
    getGroupsAzureAD: (data) =>
      Dispatch(urls.getGroupsAzureAD, data.params, data.payload),
    postSyncIntegration: (data) =>
      Dispatch(urls.postSyncIntegration, data.params, data.payload),
    postSyncAzureAD: (data) =>
      Dispatch(urls.postSyncAzureAD, data.params, data.payload),
    getSyncAzureAD: (data) =>
      Dispatch(urls.getSyncAzureAD, data.params, data.payload),
    getLastSyncAzureAD: (data) =>
      Dispatch(urls.getLastSyncAzureAD(data.params.id), {}, {}),
    putLdapSync: (data) =>
      Dispatch(urls.putLdapSync, data.params, data.payload),
    LdapConfigs: (data) =>
      Dispatch(urls.LdapConfigs, data.params, data.payload),
    DeleteLdapConfig: (data) =>
      Dispatch(urls.deleteLdapConfig(data.params.id), {}, {}),
    LdapOUs: (data) =>
      Dispatch(urls.LdapOUs, data.params, data.payload),
    LdapDetails: (data) =>
      Dispatch(urls.LdapDetails, data.params, data.payload),
    testLDAP: (data) =>
      Dispatch(urls.TestLdapConnection, data.params, data.payload),
    getTokenAad: (data) => Dispatch(urls.getToken, data.params, data.payload),

    GetSchedules: (data) =>
      Dispatch(urls.LdapSchedules, data.params, data.payload),
    DeleteSchedule: (data) =>
      Dispatch(urls.deleteSchedule(data.params.id), {}, {}),
    GetRunSchedule: (data) =>
      Dispatch(urls.RunSchedule(data.params.id), {}, {}),

    GetLogs: (data) =>
      Dispatch(urls.LdapLogs, data.params, data.payload),
    DeleteLog: (data) =>
      Dispatch(urls.deleteLog(data.params.id), {}, {}),
  };
}

export default api();
