// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import adminApis from 'apis/adminApis';
// actions
import { AdminActionTypes } from 'actions/adminActions';

export function* handleGetRp({ payload }) {
  try {
    const response = yield call(adminApis.getRp, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAdministrators({ payload }) {
  try {
    const response = yield call(adminApis.getAdministrators, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetWorkspaceAdministratorsInfo({ payload }) {
  try {
    const response = yield call(
      adminApis.getWorkspaceAdministratorsInfo,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetFilteredWorkspaceAdministrationInfo({ payload }) {
  try {
    const response = yield call(
      adminApis.getFilteredWorkspaceAdministrationInfo,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostAddLicesne({ payload }) {
  try {
    const response = yield call(adminApis.postAddLicense, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetLicesneList({ payload }) {
  try {
    const response = yield call(adminApis.getLicenseList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteLicesne({ payload }) {
  try {
    const response = yield call(adminApis.actionOnLicense, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostCheckWorkspaceId({ payload }) {
  try {
    const response = yield call(adminApis.postCheckWorkspaceId, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutUpgradeWorkspace({ payload }) {
  try {
    const response = yield call(adminApis.putUpgradeWorkspaceBusiness, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostDomain({ payload }) {
  try {
    const response = yield call(adminApis.postDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDomain({ payload }) {
  try {
    const response = yield call(adminApis.getDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteDomain({ payload }) {
  try {
    const response = yield call(adminApis.deleteDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetIndividualTokens({ payload }) {
  try {
    const response = yield call(adminApis.getIndividualTokens, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostIndividualTokens({ payload }) {
  try {
    const response = yield call(adminApis.postIndividualTokens, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetSecurityKeys({ payload }) {
  try {
    const response = yield call(adminApis.getSecurityKeys, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetSecurityKeysLogs({ payload }) {
  try {
    const response = yield call(adminApis.getSecurityKeysLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetTrustDomainsRequest({ payload }) {
  try {
    const response = yield call(adminApis.getTrustDomainsRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostActionOnTrustDomainRequest({ payload }) {
  try {
    const response = yield call(
      adminApis.putActionOnTrustDomainsRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetTokensRequest({ payload }) {
  try {
    const response = yield call(adminApis.getTokensRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutSpecialWorkspaceRequestRequest({ payload }) {
  try {
    const response = yield call(adminApis.putSpecialWorkspaceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetPairedListRequest({ payload }) {
  try {
    const response = yield call(adminApis.getPairedListRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteWorkspaceRequest({ payload }) {
  try {
    const response = yield call(adminApis.deleteWorkspaceRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetExtraActivityLogsRequest({ payload }) {
  try {
    const response = yield call(adminApis.getExtraActivityLogsRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllTokenPolicyRequest({ payload }) {
  try {
    const response = yield call(adminApis.getAllTokenPolicyRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetBillingInformationRequest({ payload }) {
  try {
    const response = yield call(
      adminApis.getBillingInformationRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutBillingPolicyRequest({ payload }) {
  try {
    const response = yield call(adminApis.putBillingPolicyRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handleGetBillingPolicyRequest({ payload }) {
  try {
    const response = yield call(adminApis.getBillingPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutWorkspaceStatusRequest({ payload }) {
  try {
    const response = yield call(adminApis.putWorkspaceStatusRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handleGetWorkspaceInvoiceHistory({ payload }) {
  try {
    const response = yield call(adminApis.getWorkspaceInvoiceHistory, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutPayWorkspaceOverdueInvoice({ payload }) {
  try {
    const response = yield call(
      adminApis.putPayWorkspaceOverdueInvoice,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePostIssueNewInvoice({ payload }) {
  try {
    const response = yield call(adminApis.postIssueNewInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePostViewSampleInvoice({ payload }) {
  try {
    const response = yield call(adminApis.postViewSampleInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handleGetCurrentInvoice({ payload }) {
  try {
    const response = yield call(adminApis.getCurrentInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutChangePaymentMethod({ payload }) {
  try {
    const response = yield call(adminApis.putChangePaymentMethod, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
export function* handleGetWorkspaceInvoice({ payload }) {
  try {
    const response = yield call(adminApis.getWorkspaceInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutSetPaidStatus({ payload }) {
  try {
    const response = yield call(adminApis.putSetPaidStatus, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handleGetDefaultBillingPolicy({ payload }) {
  try {
    const response = yield call(adminApis.getDefaultBillingPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePutProfileInfo({ payload }) {
  try {
    const response = yield call(adminApis.putProfileInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handleDeleteOneInvoice({ payload }) {
  try {
    const response = yield call(adminApis.deleteOneInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
export function* handlePutSpecialConfig({ payload }) {
  try {
    const response = yield call(adminApis.putSpecialConfig, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
export function* handlePostImportBulk({ payload }) {
  try {
    const response = yield call(adminApis.postImportBulk, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
/** ***********************************************************
 ************************************************************* */

function* adminSaga() {
  yield all([
    yield takeEvery(AdminActionTypes.GET_RP_REQUEST, handleGetRp),
    yield takeEvery(
      AdminActionTypes.GET_CUSTOMER_REQUEST,
      handleGetAdministrators,
    ),
    yield takeEvery(
      AdminActionTypes.GET_WORKSPACE_ADMINS_INFO,
      handleGetWorkspaceAdministratorsInfo,
    ),
    yield takeEvery(
      AdminActionTypes.GET_FILTERED_WORKSPACE_ADMINS_INFO,
      handleGetFilteredWorkspaceAdministrationInfo,
    ),
    yield takeEvery(
      AdminActionTypes.POST_ADDLICENSE_REQUEST,
      handlePostAddLicesne,
    ),
    yield takeEvery(
      AdminActionTypes.GET_LICENSE_LIST_REQUEST,
      handleGetLicesneList,
    ),

    yield takeEvery(
      AdminActionTypes.ACTION_LICENSE_REQUEST,
      handleDeleteLicesne,
    ),

    yield takeEvery(
      AdminActionTypes.POST_CHECK_WORKSPACE_ID_REQUEST,
      handlePostCheckWorkspaceId,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_UPGRADE_WORKSPACE_BUSINESS_REQUEST,
      handlePutUpgradeWorkspace,
    ),
    yield takeEvery(AdminActionTypes.POST_DOMAIN, handlePostDomain),
    yield takeEvery(AdminActionTypes.GET_DOMAIN, handleGetDomain),
    yield takeEvery(AdminActionTypes.DELETE_DOMAIN, handleDeleteDomain),
    yield takeEvery(
      AdminActionTypes.GET_INDIVIDUAL_TOKENS,
      handleGetIndividualTokens,
    ),
    yield takeEvery(
      AdminActionTypes.POST_INDIVIDUAL_TOKENS,
      handlePostIndividualTokens,
    ),
    yield takeEvery(AdminActionTypes.GET_SECURITY_KEYS, handleGetSecurityKeys),
    yield takeEvery(
      AdminActionTypes.GET_SECURITY_KEYS_LOGS,
      handleGetSecurityKeysLogs,
    ),
    yield takeEvery(
      AdminActionTypes.GET_TRUST_DOMAINS_REQUEST,
      handleGetTrustDomainsRequest,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_ACTION_ON_TRUST_DOMAIN_REQUEST,
      handlePostActionOnTrustDomainRequest,
    ),

    yield takeEvery(AdminActionTypes.GET_TOKENS, handleGetTokensRequest),
    yield takeEvery(
      AdminActionTypes.PUT_SPECIAL_WORKSPACE,
      handlePutSpecialWorkspaceRequestRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_PAIRED_LIST,
      handleGetPairedListRequest,
    ),
    yield takeEvery(
      AdminActionTypes.DELETE_WORKSPACE,
      handleDeleteWorkspaceRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_EXTRA_ACTIVITY_LOGS,
      handleGetExtraActivityLogsRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_TOKEN_POLICY,
      handleGetAllTokenPolicyRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_BILLING_INFORMATION,
      handleGetBillingInformationRequest,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_BILLING_POLICY,
      handlePutBillingPolicyRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_BILLING_POLICY,
      handleGetBillingPolicyRequest,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_WORKSPACE_STATUS,
      handlePutWorkspaceStatusRequest,
    ),
    yield takeEvery(
      AdminActionTypes.GET_WORKSPACE_INVOICE_HISTORY,
      handleGetWorkspaceInvoiceHistory,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_PAY_WORKSPACE_OVERDUE_INVOICE,
      handlePutPayWorkspaceOverdueInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.POST_ISSUE_NEW_INVOICE,
      handlePostIssueNewInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.POST_VIEW_SAMPLE_INVOICE,
      handlePostViewSampleInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.GET_CURRENT_INVOICE,
      handleGetCurrentInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_CHANGE_PAYMENT_METHOD,
      handlePutChangePaymentMethod,
    ),
    yield takeEvery(
      AdminActionTypes.GET_WORKSPACE_INVOICE,
      handleGetWorkspaceInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_SET_PAID_STATUS,
      handlePutSetPaidStatus,
    ),
    yield takeEvery(AdminActionTypes.PUT_PROFILE_INFO, handlePutProfileInfo),
    yield takeEvery(
      AdminActionTypes.GET_DEFAULT_BILLING_POLICY,
      handleGetDefaultBillingPolicy,
    ),
    yield takeEvery(
      AdminActionTypes.DELETE_ONE_INVOICE,
      handleDeleteOneInvoice,
    ),
    yield takeEvery(
      AdminActionTypes.PUT_SPECIAL_CONFIG,
      handlePutSpecialConfig,
    ),
    yield takeEvery(AdminActionTypes.POST_IMPORT_BULK, handlePostImportBulk),
  ]);
}

export default adminSaga;
