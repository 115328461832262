import Dispatch from './client';

const urls = {
  getRps: {
    url: '/superAdmin/workspaces',
    method: 'get',
  },

  getAdministrators: {
    url: '/superAdmin/administrators/admins/business',
    method: 'get',
  },
  getWorkspaceAdministratorsInfo: (id) => ({
    url: `/superAdmin/administrators/workspace/${id}`,
    method: 'get',
  }),
  getFilteredWorkspaceAdministrationInfo: (id) => ({
    url: `/superAdmin/administrators/workspace/${id}/filter`,
    method: 'get',
  }),
  postAddLicense: (method, id) => ({
    url: `/superAdmin/workspaces/${id}/licenses`,
    method: method,
  }),
  Licenses: (method, id, licenseId) => ({
    url: licenseId
      ? `/superAdmin/workspaces/${id}/licenses/${licenseId}`
      : id
        ? `/superAdmin/workspaces/${id}/licenses`
        : '/superAdmin/requestedLicense',
    method: method,
  }),

  postCheckWorkspaceId: {
    url: `/administrator/workspace/checkWorkspaceId`,
    method: 'post',
  },
  putUpgradeWorkspaceBusiness: {
    url: `/administrator/workspace`,
    method: 'put',
  },
  domian: (method, id, domainId) => ({
    url: domainId
      ? `/superAdmin/workspaces/${id}/trustDomains/${domainId}`
      : `/superAdmin/workspaces/${id}/trustDomains`,
    method: method,
  }),
  indiVidualTokens: (method, id) => ({
    url: `/superAdmin/trustDomains/${id}/individualToken`,
    method,
  }),
  getSecurityKeys: (workspaceId) => ({
    method: 'get',
    url: `/superAdmin/workspaces/${workspaceId}/securityKeys`,
  }),
  getSecurityKeysLogs: (id) => ({
    method: 'get',
    url: `/superAdmin/securityKeys/${id}/logs`,
  }),
  trustDomain: (method) => ({
    method: method,
    url: `/superAdmin/trustDomains`,
  }),

  getTokens: {
    url: '/superAdmin/tokens',
    method: 'get',
  },
  putSpecialWorkspace: (workspaceId) => ({
    method: 'put',
    url: `/superAdmin/workspaces/${workspaceId}/special`,
  }),
  getPairedlist: (id) => ({
    method: 'get',
    url: `/superAdmin/tokens/${id}/pairedList`,
  }),
  deletWorkspace: {
    method: 'delete',
    url: `/superAdmin/workspaces`,
  },
  getExtraActivityLogs: (id) => ({
    method: 'get',
    url: `/superAdmin/tokens/${id}/extraActivityLogs`,
  }),
  getAllTokenPolicy: (id) => ({
    url: `/superAdmin/workspaces/${id}/tokenPolicies`,
    method: 'get',
  }),
  getBillingInformation: (id) => ({
    url: `/superAdmin/workspaces/${id}/billing`,
    method: 'get',
  }),
  putBillingPolicy: (id) => ({
    url: `/superAdmin/workspaces/${id}/billingPolicy`,
    method: 'put',
  }),
  putWorkspaceStatus: (id) => ({
    url: `/superAdmin/workspaces/${id}/status`,
    method: 'put',
  }),
  getWorkspaceInvoiceHistory: (workspaceId) => ({
    url: `/superAdmin/bills/history/workspaces/${workspaceId}`,
    method: 'get',
  }),

  putPayWorkspaceOverdueInvoice: (invoiceId) => ({
    url: `/superAdmin/bills/${invoiceId}/checkout`,
    method: 'put',
  }),
  getBillingPolicy: (workspaceId) => ({
    url: `/superAdmin/workspaces/${workspaceId}/billing`,
    method: 'get',
  }),
  postIssueNewInvoice: {
    url: `/superAdmin/bills/new`,
    method: 'post',
  },
  postViewSampleInvoice: {
    url: `/superAdmin/bills/sample/pdf/`,
    method: 'post',
  },
  getCurrentInvoice: (workspaceId) => ({
    url: `/superAdmin/bills/current/${workspaceId}`,
    method: 'get',
  }),
  putChangePaymentMethod: (type) => ({
    url: `/superAdmin/workspaces/billing/method/${
      type === 1 ? 'payg-to-prepaid' : 'prepaid-to-payg'
    }`,
    method: 'put',
  }),
  getWorkspaceInvoice: (invoiceId, workspaceId) => ({
    url: `/superAdmin/bills/pdf/${invoiceId}/${workspaceId}`,
    method: 'get',
  }),
  putSetPaidStatus: (invoiceId) => ({
    url: `/superadmin/bills/approve/${invoiceId}`,
    method: 'put',
  }),
  getDefaultBillingPolicy: {
    url: '/superadmin/bills/billingPolicy/default',
    method: 'get',
  },
  putProfileInfo: {
    url: '/administrator/profile',
    method: 'put',
  },
  deleteOneInvoice: (invoiceId) => ({
    url: `/superadmin/bills/${invoiceId}`,
    method: 'delete',
  }),
  putSpecialConfig: {
    url: `/superAdmin/workspaces/special-config`,
    method: 'put',
  },
  postImportBulk: (workspaceId) => ({
    url: `/superAdmin/workspaces/${workspaceId}/bulk-import`,
    method: 'post',
  }),
};

function api() {
  return {
    getRp: (data) => Dispatch(urls.getRps, data.params, data.payload),

    getAdministrators: (data) =>
      Dispatch(urls.getAdministrators, data.params, data.payload),
    getWorkspaceAdministratorsInfo: (data) =>
      Dispatch(
        urls.getWorkspaceAdministratorsInfo(data.id),
        data.params,
        data.payload,
      ),
    getFilteredWorkspaceAdministrationInfo: (data) =>
      Dispatch(
        urls.getFilteredWorkspaceAdministrationInfo(data.id),
        data.params,
        data.payload,
      ),
    postAddLicense: (data) =>
      Dispatch(urls.postAddLicense('post', data.params.id), {}, data.payload),
    getLicenseList: (data) =>
      Dispatch(urls.Licenses('get', data.params.id), {}, {}),
    actionOnLicense: (data) =>
      Dispatch(
        urls.Licenses(
          data.params.method,
          data.params.id,
          data.params.licenseId,
        ),
        {},
        data.payload,
      ),

    postCheckWorkspaceId: (data) =>
      Dispatch(urls.postCheckWorkspaceId, data.params, data.payload),
    putUpgradeWorkspaceBusiness: (data) =>
      Dispatch(urls.putUpgradeWorkspaceBusiness, {}, data.payload),
    postDomain: (data) =>
      Dispatch(urls.domian('post', data.params.id), {}, data.payload),
    getDomain: (data) =>
      Dispatch(urls.domian('get', data.params.id), {}, data.payload),
    deleteDomain: (data) =>
      Dispatch(
        urls.domian('delete', data.params.id, data.params.domainId),
        {},
        data.payload,
      ),
    getIndividualTokens: (data) =>
      Dispatch(urls.indiVidualTokens('get', data.params.id), {}, data.payload),
    postIndividualTokens: (data) =>
      Dispatch(urls.indiVidualTokens('post', data.params.id), {}, data.payload),
    getSecurityKeys: (data) =>
      Dispatch(urls.getSecurityKeys(data.payload.workspaceId), data.params, {}),
    getSecurityKeysLogs: (data) =>
      Dispatch(urls.getSecurityKeysLogs(data.params.id), data.params, {}),
    getTrustDomainsRequest: (data) =>
      Dispatch(urls.trustDomain('get'), data.params, data.payload),
    putActionOnTrustDomainsRequest: (data) =>
      Dispatch(urls.trustDomain('put'), data.params, data.payload),

    getTokensRequest: (data) =>
      Dispatch(urls.getTokens, data.params, data.payload),
    putSpecialWorkspaceRequest: (data) =>
      Dispatch(urls.putSpecialWorkspace(data.params.id), {}, data.payload),
    getPairedListRequest: (data) =>
      Dispatch(urls.getPairedlist(data.payload.id), {}, {}),
    deleteWorkspaceRequest: (data) =>
      Dispatch(urls.deletWorkspace, data.params, data.payload),
    getExtraActivityLogsRequest: (data) =>
      Dispatch(urls.getExtraActivityLogs(data.payload.id), data.params, {}),
    getAllTokenPolicyRequest: (data) =>
      Dispatch(urls.getAllTokenPolicy(data.params.id), {}, data.payload),
    getBillingInformationRequest: (data) =>
      Dispatch(urls.getBillingInformation(data.workspaceId), {}, data.payload),
    putBillingPolicyRequest: (data) =>
      Dispatch(
        urls.putBillingPolicy(data.workspaceId),
        data.params,
        data.payload,
      ),
    putWorkspaceStatusRequest: (data) =>
      Dispatch(
        urls.putWorkspaceStatus(data.workspaceId),
        data.params,
        data.payload,
      ),
    getWorkspaceInvoiceHistory: (data) =>
      Dispatch(
        urls.getWorkspaceInvoiceHistory(data.workspaceId),
        data.params,
        data.payload,
      ),
    putPayWorkspaceOverdueInvoice: (data) =>
      Dispatch(
        urls.putPayWorkspaceOverdueInvoice(data.invoiceId),
        data.params,
        data.payload,
      ),
    getBillingPolicy: (data) =>
      Dispatch(
        urls.getBillingPolicy(data.workspaceId),
        data.params,
        data.payload,
      ),
    postIssueNewInvoice: (data) =>
      Dispatch(urls.postIssueNewInvoice, data.params, data.payload),
    postViewSampleInvoice: (data) =>
      Dispatch(urls.postViewSampleInvoice, data.params, data.payload),
    putChangePaymentMethod: (data) =>
      Dispatch(
        urls.putChangePaymentMethod(data.billingPolicyType),
        data.params,
        data.payload,
      ),
    getCurrentInvoice: (data) =>
      Dispatch(
        urls.getCurrentInvoice(data.workspaceId),
        data.params,
        data.payload,
      ),
    getWorkspaceInvoice: (data) =>
      Dispatch(
        urls.getWorkspaceInvoice(data.invoiceId, data.workspaceId),
        data.params,
        data.payload,
      ),
    putSetPaidStatus: (data) =>
      Dispatch(
        urls.putSetPaidStatus(data.invoiceId),
        data.params,
        data.payload,
      ),
    getDefaultBillingPolicy: (data) =>
      Dispatch(urls.getDefaultBillingPolicy, data.params, data.payload),
    putProfileInfo: (data) =>
      Dispatch(urls.putProfileInfo, data.params, data.payload),
    deleteOneInvoice: (data) =>
      Dispatch(
        urls.deleteOneInvoice(data.invoiceId),
        data.params,
        data.payload,
      ),
    putSpecialConfig: (data) =>
      Dispatch(urls.putSpecialConfig, data.params, data.payload),
    postImportBulk: (data) =>
      Dispatch(
        urls.postImportBulk(data.workspaceId),
        data.params,
        data.payload,
      ),
  };
}

export default api();
