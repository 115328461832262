import { action } from 'typesafe-actions';

export const statisticsActionTypes = {
  GET_STATISTICS: '@@statistics/GET_STATISTICS',
  GET_TOP_WORKSPACE: '@@statistics/GET_TOP_WORKSPACE',
};

export const getStatistics = (data) =>
  action(statisticsActionTypes.GET_STATISTICS, data);
export const getTopWorkspace = (data) =>
  action(statisticsActionTypes.GET_TOP_WORKSPACE, data);
