// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import customerApis from 'apis/customersApis';
// actions
import { CustomersActionTypes } from 'actions/customersActions';

export function* handleGetCustomerInfo({ payload }) {
  try {
    const response = yield call(customerApis.getCustomerInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostCustomerInfo({ payload }) {
  try {
    const response = yield call(customerApis.postCustomerInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostCustomerSetupSecurity({ payload }) {
  try {
    const response = yield call(
      customerApis.postCustomerSetUpSecurity,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostResetPassword({ payload }) {
  try {
    const response = yield call(
      customerApis.postCustomerResetPassword,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostContactInfo({ payload }) {
  try {
    const response = yield call(customerApis.postContactInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostVerifyContactInfo({ payload }) {
  try {
    const response = yield call(customerApis.postVerifyContactInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostVerificationDnsRecord({ payload }) {
  try {
    const response = yield call(
      customerApis.postVerificationDnsRecord,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDomains({ payload }) {
  try {
    const response = yield call(customerApis.getDomains, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostVerifyDomain({ payload }) {
  try {
    const response = yield call(customerApis.postVerifyDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteDomain({ payload }) {
  try {
    const response = yield call(customerApis.deleteDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostMFAComplexity({ payload }) {
  try {
    const response = yield call(customerApis.postMFA, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostPinComplexity({ payload }) {
  try {
    const response = yield call(customerApis.postPin, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostUserPermission({ payload }) {
  try {
    const response = yield call(customerApis.postUserPermission, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostSecurityKeySetting({ payload }) {
  try {
    const response = yield call(customerApis.postSecurityKeySetting, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* customerSaga() {
  yield all([
    yield takeEvery(
      CustomersActionTypes.GET_CUSTOMER_INFO_REQUEST,
      handleGetCustomerInfo,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_INFO_REQUEST,
      handlePostCustomerInfo,
    ),
    yield takeEvery(
      CustomersActionTypes.GET_CUSTOMER_RESET_PASSWORD,
      handlePostResetPassword,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_SETUP_SECURITY,
      handlePostCustomerSetupSecurity,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_CONTACT_INFO_REQUEST,
      handlePostContactInfo,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_VERIFY_REQUEST,
      handlePostVerifyContactInfo,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_VERIFICATION_DNS,
      handlePostVerificationDnsRecord,
    ),
    yield takeEvery(CustomersActionTypes.GET_DOMAINS, handleGetDomains),
    yield takeEvery(
      CustomersActionTypes.POST_VERIFY_DOMAIN,
      handlePostVerifyDomain,
    ),
    yield takeEvery(CustomersActionTypes.DELETE_DOMAIN, handleDeleteDomain),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_MFA_COMPLEXITY,
      handlePostMFAComplexity,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_PIN_COMPLEXITY,
      handlePostPinComplexity,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_USER_PERMISSION,
      handlePostUserPermission,
    ),
    yield takeEvery(
      CustomersActionTypes.POST_CUSTOMER_SECURITY_KEY_SETTING,
      handlePostSecurityKeySetting,
    ),
  ]);
}

export default customerSaga;
