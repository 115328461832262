import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import { en, fa } from './locales';
import { LANGUAGE_STORAGE_KEY } from '../constants';

i18n.use(reactI18nextModule).init({
  resources: {
    en,
    fa,
  },
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
  fallbackLng: 'en',
  lng: localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? 'fa',
});

export default i18n;
