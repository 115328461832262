// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import individualApis from 'apis/individualApis';
// actions
import { IndividualActionTypes } from 'actions/individualAction';

export function* handleGetActivityLog({ payload }) {
  try {
    const response = yield call(individualApis.getActivityLog, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetInformation({ payload }) {
  try {
    const response = yield call(individualApis.getInformation, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutReactive({ payload }) {
  try {
    const response = yield call(individualApis.reactive, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostRedeemPromoCode({ payload }) {
  try {
    const response = yield call(individualApis.postRedeemPromo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostRedeemPromoCodeRegister({ payload }) {
  try {
    const response = yield call(
      individualApis.postRedeemPromoRegister,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* licenseSaga() {
  yield all([
    yield takeEvery(
      IndividualActionTypes.GET_ACTIVITY_LOGS,
      handleGetActivityLog,
    ),
    yield takeEvery(
      IndividualActionTypes.GET_INFORMATION,
      handleGetInformation,
    ),
    yield takeEvery(
      IndividualActionTypes.PUT_REACTIVE_SECURITY,
      handlePutReactive,
    ),
    yield takeEvery(
      IndividualActionTypes.POST_REDEEM_PROMO_CODE,
      handlePostRedeemPromoCode,
    ),
    yield takeEvery(
      IndividualActionTypes.POST_REDEEM_PROMO_CODE_REGISTER,
      handlePostRedeemPromoCodeRegister,
    ),
  ]);
}

export default licenseSaga;
