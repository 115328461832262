import Dispatch from './client';

const apis = {
  getMySessions: () => ({
    url: `/sessions`,
    method: 'get',
  }),
  terminateMySession: (sessionId) => ({
    url: `/sessions/${sessionId}`,
    method: 'delete',
  }),
};

function api() {
  return {
    getMySessions: (data) =>
      Dispatch(apis.getMySessions(), data.params, data.payload),
    terminateMySession: (data) =>
      Dispatch(
        apis.terminateMySession(data?.params?.sessionId),
        data.params,
        data.payload,
      ),
  };
}

export default api;
