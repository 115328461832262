import React from 'react';
import ReactDOM from 'react-dom';
// import 'babel-polyfill';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createHashHistory } from 'history';
import { Provider, ReactReduxContext } from 'react-redux';
import 'antd/dist/antd.css';
import configureStore from './stores/configureStore';
import 'styles/App.less';
import './index.css';
import 'styles/custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import './i18n';


const history = createHashHistory({});

const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
ReactDOM.render(
  <CookiesProvider>
    <Provider store={store} context={ReactReduxContext}>
      <App history={history} />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
