// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import fcpDevicesApis from 'apis/fcpDevicesApis';
// actions
import { DevicesActionTypes } from 'actions/fcpDevicesActions';

export function* handleGetFCPConfig({ payload }) {
  try {
    const response = yield call(fcpDevicesApis.getFCPconfigRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetFCPPCs({ payload }) {
  try {
    const response = yield call(fcpDevicesApis.getFCPPCsRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteFCPPC({ payload }) {
  try {
    const response = yield call(fcpDevicesApis.deleteFCPPCRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutWindowsLoginforFCP({ payload }) {
  try {
    const response = yield call(
      fcpDevicesApis.putWindowsLoginforFCPRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostFCPCredentialRequest({ payload }) {
  try {
    const response = yield call(
      fcpDevicesApis.postFCPCrededntialRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* fcpDevicesSaga() {
  yield all([
    yield takeEvery(
      DevicesActionTypes.GET_FCP_CONFIG_REQUEST,
      handleGetFCPConfig,
    ),
    yield takeEvery(DevicesActionTypes.GET_FCP_PCS_REQUEST, handleGetFCPPCs),
    yield takeEvery(
      DevicesActionTypes.DELETE_FCP_PC_REQUEST,
      handleDeleteFCPPC,
    ),
    yield takeEvery(
      DevicesActionTypes.PUT_WINDOWS_LOGIN_FOR_FCP_REQUEST,
      handlePutWindowsLoginforFCP,
    ),
    yield takeEvery(
      DevicesActionTypes.POST_FCP_CREDENTIAL_REQUEST,
      handlePostFCPCredentialRequest,
    ),
  ]);
}

export default fcpDevicesSaga;
