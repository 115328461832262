import { all, call, takeEvery } from 'redux-saga/effects';
import apis from 'apis/organizationUnitsApi';
import { OUsActionTypes } from 'actions/organizationUnitsActions';

export function* getUsers({ payload }) {
  try {
    const response = yield call(apis.getUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* getOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.getOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* getOrganizationUnitsNames({ payload }) {
  try {
    const response = yield call(apis.getOrganizationUnitsNames, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* getOrganizationUnit({ payload }) {
  try {
    const response = yield call(apis.getOrganizationUnit, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* createOrganizationUnit({ payload }) {
  try {
    const response = yield call(apis.createOrganizationUnit, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* deleteOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.deleteOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* updateOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.updateOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* getUserOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.getUserOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* deleteUserFromOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.deleteUserFromOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* addUserToOrganizationUnits({ payload }) {
  try {
    const response = yield call(apis.addUserToOrganizationUnits, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* OUsSaga() {
  yield all([
    yield takeEvery(OUsActionTypes.GET_USERS, getUsers),
    yield takeEvery(
      OUsActionTypes.GET_ORGANIZATION_UNITS,
      getOrganizationUnits,
    ),
    yield takeEvery(
      OUsActionTypes.GET_ORGANIZATION_UNITS_NAMES,
      getOrganizationUnitsNames,
    ),
    yield takeEvery(OUsActionTypes.GET_ORGANIZATION_UNIT, getOrganizationUnit),
    yield takeEvery(
      OUsActionTypes.CREATE_ORGANIZATION_UNIT,
      createOrganizationUnit,
    ),
    yield takeEvery(
      OUsActionTypes.DELETE_ORGANIZATION_UNITS,
      deleteOrganizationUnits,
    ),
    yield takeEvery(
      OUsActionTypes.UPDATE_ORGANIZATION_UNITS,
      updateOrganizationUnits,
    ),

    yield takeEvery(
      OUsActionTypes.GET_USER_ORGANIZATION_UNITS,
      getUserOrganizationUnits,
    ),

    yield takeEvery(
      OUsActionTypes.DELETE_USER_FROM_ORGANIZATION_UNITS,
      deleteUserFromOrganizationUnits,
    ),

    yield takeEvery(
      OUsActionTypes.ADD_USER_TO_ORGANIZATION_UNITS,
      addUserToOrganizationUnits,
    ),
  ]);
}

export default OUsSaga;
