import Dispatch from './client';

const urls = {
  groups: (method, id) => ({
    url: id ? `/administrator/groups/${id}` : '/administrator/groups',
    method,
  }),
  getUser: {
    method: 'get',
    url: '/administrator/tokens/ids',
  },
  putUsersInGroup: (method, groupId) => ({
    method: method,
    url: `/administrator/groups/${groupId}/users`,
  }),
  AssignSecurityKey: (id) => ({
    url: `/administrator/groups/${id}`,
    method: 'put',
  }),
  editGroup: (id) => ({
    url: `/administrator/groups/${id}/edit`,
    method: 'put',
  }),
  editGroupInfo: (id) => ({
    url: `/administrator/groups/${id}/info`,
    method: 'put',
  }),
  putSuspendManyGroups: {
    method: 'put',
    url: '/administrator/groups/suspendMany',
  },
  postAddGroupUsers: (id) => ({
    url: `/administrator/groups/${id}/addUsers`,
    method: 'post',
  }),
  deleteGroupUsers: (id) => ({
    url: `/administrator/groups/${id}/deleteUsers`,
    method: 'delete',
  }),
};

function api() {
  return {
    getGroups: (data) =>
      Dispatch(urls.groups('get'), data.params, data.payload),
    putAssignSecurityKeyGroups: (data) =>
      Dispatch(urls.AssignSecurityKey(data.groupId), data.params, data.payload),
    getUserForAssignInGroup: (data) =>
      Dispatch(urls.getUser, data.params, data.payload),
    postGroups: (data) =>
      Dispatch(urls.groups('post'), data.params, data.payload),
    putUsersInGroups: (data) =>
      Dispatch(
        urls.putUsersInGroup(data.method || 'put', data.groupId),
        data.params,
        data.payload,
      ),
    getOneGroupData: (data) =>
      Dispatch(urls.groups('get', data.groupId), data.params, data.payload),
    putEditOneGroup: (data) =>
      Dispatch(urls.editGroup(data.groupId), data.params, data.payload),
    putEditOneGroupInfo: (data) =>
      Dispatch(urls.editGroupInfo(data.groupId), {}, data.payload),
    deleteOneGroup: (data) =>
      Dispatch(urls.groups('delete', data.groupId), data.params, data.payload),
    putSuspendManyGroups: (data) =>
      Dispatch(urls.putSuspendManyGroups, {}, data.payload),
    postAddGroupUsers: (data) =>
      Dispatch(urls.postAddGroupUsers(data.groupId), {}, data.payload),
    deleteGroupUsers: (data) =>
      Dispatch(urls.deleteGroupUsers(data.groupId), {}, data.payload),
  };
}

export default api();
