import { action } from 'typesafe-actions';

export const UsersActionTypes = {
  GET_USERS_INFO_REQUEST: '@@allUsers/GET_USERS_INFO_REQUEST',
  POST_USERS_SETUP_SECURITY: '@@allUsers/setupSecurity',
  POST_USERS_CONTACT_INFO_REQUEST: '@@allUsers/POST_USERS_CONTACT_INFO_REQUEST',
  POST_USERS_VERIFY_REQUEST: '@@allUsers/POST_USERS_VERIFY_REQUEST',
  POST_USERS_INFO_REQUEST: '@@allUsers/POST_USERS_TOKEN_INFO_REQUEST',
  GET_USERS_AUTHORIZATION: '@@allUsers/GET_USERS_AUTHORIZATION',
  GET_RIBBON_DATA: '@@allUsers/GET_RIBBON_DATA',
  POST_DELETE_USER_ACCOUNT_REQUEST: '@@users/POST_DELETE_USER_ACCOUNT_REQUEST',
};

export const getUserInfoRequest = (data) =>
  action(UsersActionTypes.GET_USERS_INFO_REQUEST, data);
export const postUserSetupSecurity = (data) =>
  action(UsersActionTypes.POST_USERS_SETUP_SECURITY, data);
export const postUserContactInfoRequest = (data) =>
  action(UsersActionTypes.POST_USERS_CONTACT_INFO_REQUEST, data);
export const postUserVerifyRequest = (data) =>
  action(UsersActionTypes.POST_USERS_VERIFY_REQUEST, data);
export const postUserInfoRequest = (data) =>
  action(UsersActionTypes.POST_USERS_INFO_REQUEST, data);
export const getUserAuthorization = (data) =>
  action(UsersActionTypes.GET_USERS_AUTHORIZATION, data);
export const getAdminRibbonData = (data) =>
  action(UsersActionTypes.GET_RIBBON_DATA, data);
export const postDeleteUserAccountRequest = (data) =>
  action(UsersActionTypes.POST_DELETE_USER_ACCOUNT_REQUEST, data);
