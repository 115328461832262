import Dispatch from './client';

const urls = {
  getStatistics: {
    url: '/superadmin/dailyData',
    method: 'get',
  },
  getTopWorkspace: {
    url: '/superadmin/workspaces/stats',
    method: 'get',
  },
};

function api() {
  return {
    getStatistics: (data) =>
      Dispatch(urls.getStatistics, data.params, data.payload),
    getTopWorkspace: (data) =>
      Dispatch(urls.getTopWorkspace, data.params, data.payload),
  };
}

export default api();
