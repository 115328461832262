// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import namedListsApis from 'apis/namedListsApis';
// actions
import { NamedListsActionTypes } from 'actions/namedListsActions';

export function* handlePostAddNewNamedList({ payload }) {
  try {
    const response = yield call(namedListsApis.postAddNewNamedList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllNamedLists({ payload }) {
  try {
    const response = yield call(namedListsApis.getAllNamedLists, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteNamedList({ payload }) {
  try {
    const response = yield call(namedListsApis.deleteNamedList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleEditNamedList({ payload }) {
  try {
    const response = yield call(namedListsApis.editNamedList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetOneNamedList({ payload }) {
  try {
    const response = yield call(namedListsApis.getOneNamedList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetNamedListsPolicies({ payload }) {
  try {
    const response = yield call(namedListsApis.getNamedListsPolicies, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

function* namedListsSaga() {
  yield all([
    yield takeEvery(
      NamedListsActionTypes.POST_ADD_NAMED_LIST,
      handlePostAddNewNamedList,
    ),
    yield takeEvery(
      NamedListsActionTypes.GET_ALL_NAMED_LISTS,
      handleGetAllNamedLists,
    ),
    yield takeEvery(
      NamedListsActionTypes.DELETE_NAMED_LIST,
      handleDeleteNamedList,
    ),
    yield takeEvery(NamedListsActionTypes.EDIT_NAMED_LIST, handleEditNamedList),
    yield takeEvery(
      NamedListsActionTypes.GET_ONE_NAMED_LIST,
      handleGetOneNamedList,
    ),
    yield takeEvery(
      NamedListsActionTypes.GET_NAMED_LISTS_POLICIES,
      handleGetNamedListsPolicies,
    ),
  ]);
}

export default namedListsSaga;
