import Dispatch from './client';

const urls = {
  customerInfo(method, url) {
    return {
      url: `/administrator/${url ? 'detials/' + url : 'detials'}`,
      method,
    };
  },
  postContactInfo: {
    url: '/administrator/detials/contactInfo',
    method: 'post',
  },
  postVerifyContactInfo: {
    url: '/administrator/detials/verifyCode',
    method: 'post',
  },
  postResetPassword: {
    url: '/administrator/changePassword',
    method: 'post',
  },
  postSetupSecurity: {
    // url: '/administrator/setUpSecurity',
    url: '/challenge',
    method: 'get',
  },
  postAnswerToSiteAdmin: {
    url: '/administrator/answerForSiteAdmin',
    method: 'post',
  },
  postVerificationDnsRecord: {
    url: '/administrator/workspace/domain/verification/dns-record',
    method: 'post',
  },
  getDomains: {
    url: '/administrator/workspace/domains',
    method: 'get',
  },
  postVerifyDomain: (dnsId) => {
    return {
      url: `/administrator/workspace/domain/verification/dns-record/${dnsId}/verify`,
      method: 'get',
    };
  },
  deleteDomain: (dnsId) => {
    return {
      url: `/administrator/workspace/domain/verification/dns-record/${dnsId}`,
      method: 'delete',
    };
  },
  postMFA: {
    url: '/administrator/detials/MFAConfig',
    method: 'post',
  },
  postPin: {
    url: '/administrator/detials/pinConfig',
    method: 'post',
  },
  postUserPermission: {
    url: '/administrator/detials/userPermission',
    method: 'post',
  },
  postSecurityKeySetting: {
    url: '/administrator/detials/securitykeySetting',
    method: 'post',
  },
};

function api() {
  return {
    getCustomerInfo: (data) =>
      Dispatch(urls.customerInfo('get'), data.params, data.payload),
    postContactInfo: (data) =>
      Dispatch(urls.postContactInfo, data.params, data.payload),
    postCustomerResetPassword: (data) =>
      Dispatch(urls.postResetPassword, data.params, data.payload),
    postCustomerSetUpSecurity: (data) =>
      Dispatch(urls.postSetupSecurity, data.params, data.payload),
    postCustomerInfo: (data) =>
      Dispatch(urls.customerInfo('post', data.url), data.params, data.payload),
    postVerifyContactInfo: (data) =>
      Dispatch(urls.postVerifyContactInfo, data.params, data.payload),
    postAnswerToSiteAdmin: (data) =>
      Dispatch(urls.postAnswerToSiteAdmin, data.params, data.payload),
    postVerificationDnsRecord: (data) =>
      Dispatch(urls.postVerificationDnsRecord, data.params, data.payload),
    getDomains: (data) => Dispatch(urls.getDomains, data.params, data.payload),
    postVerifyDomain: (data) =>
      Dispatch(urls.postVerifyDomain(data.dnsId), data.params, data.payload),
    deleteDomain: (data) =>
      Dispatch(urls.deleteDomain(data.dnsId), data.params, data.payload),
    postMFA: (data) => Dispatch(urls.postMFA, data.params, data.payload),
    postPin: (data) => Dispatch(urls.postPin, data.params, data.payload),
    postUserPermission: (data) =>
      Dispatch(urls.postUserPermission, data.params, data.payload),
    postSecurityKeySetting: (data) =>
      Dispatch(urls.postSecurityKeySetting, data.params, data.payload),
  };
}

export default api();
