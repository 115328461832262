import { action } from 'typesafe-actions';

export const NamedListsActionTypes = {
  POST_ADD_NAMED_LIST: '@@NAMED_LISTS/POST_ADD_NAMED_LIST',
  DELETE_NAMED_LIST: '@@NAMED_LISTS/DELETE_NAMED_LIST',
  GET_ALL_NAMED_LISTS: '@@NAMED_LISTS/GET_ALL_NAMED_LISTS',
  EDIT_NAMED_LIST: '@@NAMED_LISTS/EDIT_NAMED_LIST',
  GET_ONE_NAMED_LIST: '@@NAMED_LISTS/GET_ONE_NAMED_LIST',
  GET_NAMED_LISTS_POLICIES: '@@NAMED_LISTS/GET_NAMED_LISTS_POLICIES',
};

export const postAddNamedListRequest = (data) =>
  action(NamedListsActionTypes.POST_ADD_NAMED_LIST, data);

export const getAllNamedListsRequest = (data) =>
  action(NamedListsActionTypes.GET_ALL_NAMED_LISTS, data);
export const deleteNamedListRequest = (data) =>
  action(NamedListsActionTypes.DELETE_NAMED_LIST, data);
export const editNamedListRequest = (data) =>
  action(NamedListsActionTypes.EDIT_NAMED_LIST, data);
export const getOneNamedListRequest = (data) =>
  action(NamedListsActionTypes.GET_ONE_NAMED_LIST, data);
export const getNamedListsPoliciesRequest = (data) =>
  action(NamedListsActionTypes.GET_NAMED_LISTS_POLICIES, data);
