import Dispatch from './client';

const urls = {
  addNewNamedList: {
    url: '/administrator/namedList/create',
    method: 'post',
  },
  getallNamedLists: {
    url: '/administrator/namedList',
    method: 'get',
  },
  getNamedListsPolicies: {
    url: '/administrator/namedList/name',
    method: 'get',
  },
  deleteNamedList: {
    url: `/administrator/namedList`,
    method: 'delete',
  },
  editNamedList: (namedListId) => {
    return { url: `/administrator/namedList/${namedListId}`, method: 'put' };
  },
  getOneNamedList: (namedListId) => {
    return { url: `/administrator/namedList/${namedListId}`, method: 'get' };
  },
};

// /administrator/namedList/name                GET

function api() {
  return {
    postAddNewNamedList: (data) =>
      Dispatch(urls.addNewNamedList, data.params, data.payload),
    getAllNamedLists: (data) =>
      Dispatch(urls.getallNamedLists, data.params, data.payload),
    getNamedListsPolicies: (data) =>
      Dispatch(urls.getNamedListsPolicies, data.params, data.payload),
    deleteNamedList: (data) =>
      Dispatch(urls.deleteNamedList, data.params, data.payload),
    editNamedList: (data) =>
      Dispatch(
        urls.editNamedList(data?.params?.namedListId),
        data.params,
        data.payload,
      ),
    getOneNamedList: (data) =>
      Dispatch(
        urls.getOneNamedList(data?.payload?.namedListId),
        data.params,
        data.payload,
      ),
  };
}

export default api();
