// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import tokenPolicyApis from 'apis/tokenPolicyApi';
// actions
import { TokenPolicyActionTypes } from 'actions/tokenPolicyActions';

export function* handleGet({ payload }) {
  try {
    const response = yield call(tokenPolicyApis.getTokenPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePost({ payload }) {
  try {
    const response = yield call(tokenPolicyApis.postTokenPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutDefualtTokenPolicy({ payload }) {
  try {
    const response = yield call(tokenPolicyApis.putDefaultTokenPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetDefualtTokenPolicy({ payload }) {
  try {
    const response = yield call(tokenPolicyApis.getDefaultTokenPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* tokenPolicySaga() {
  yield all([
    yield takeEvery(TokenPolicyActionTypes.GET_TOKEN_POLICY_REQUEST, handleGet),
    yield takeEvery(
      TokenPolicyActionTypes.POST_TOKEN_POLICY_REQUEST,
      handlePost,
    ),
    yield takeEvery(
      TokenPolicyActionTypes.PUT_DEFAULT_TOKEN_POLICY_REQUEST,
      handlePutDefualtTokenPolicy,
    ),
    yield takeEvery(
      TokenPolicyActionTypes.GET_DEFAULT_TOKEN_POLICY_REQUEST,
      handleGetDefualtTokenPolicy,
    ),
  ]);
}

export default tokenPolicySaga;
