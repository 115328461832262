import Dispatch from './client';

const urls = {
  getFCPconfig: {
    url: `/administrator/workspace/fcp/config`,
    method: 'get',
  },
  getFCPPCs: {
    url: `/administrator/workspace/fcp/pcs`,
    method: 'get',
  },
  deleteFCPPC: {
    url: `/administrator/workspace/fcp/pcs`,
    method: 'delete',
  },
  putWindowsLoginforFCP: (_id) => ({
    url: `/administrator/workspace/fcp/pcs/${_id}`,
    method: 'put',
  }),
  putAddSecurityKeyforFCP: (_id) => ({
    url: `/administrator/workspace/fcp/pcs/${_id}/security-key`,
    method: 'put',
  }),
  getAllSecurityKeyforFCP: (_id) => ({
    url: `/administrator/workspace/fcp/pcs/${_id}/security-key`,
    method: 'get',
  }),
  deleteSecurityKeyforFCP: (pcId, skId) => ({
    url: `/administrator/workspace/fcp/pcs/${pcId}/security-key/${skId}`,
    method: 'delete',
  }),
  getFCPPCAvailableSharedDevices: (pcId) => ({
    url: `/administrator/workspace/fcp/pcs/${pcId}/security-key/available`,
    method: 'get',
  }),
  postFCPCrededntial: (tokenId) => ({
    url: `/administrator/workspace/fcp/credential/${tokenId}`,
    method: 'post',
  }),
};

function api() {
  return {
    getFCPconfigRequest: (data) =>
      Dispatch(urls.getFCPconfig, {}, data.payload),
    getFCPPCsRequest: (data) =>
      Dispatch(urls.getFCPPCs, data.params, data.payload),
    deleteFCPPCRequest: (data) => Dispatch(urls.deleteFCPPC, {}, data.payload),
    putWindowsLoginforFCPRequest: (data) =>
      Dispatch(urls.putWindowsLoginforFCP(data.params._id), {}, data.payload),
    postFCPCrededntialRequest: (data) =>
      Dispatch(urls.postFCPCrededntial(data.params._id), {}, data.payload),
  };
}

export default api();
