import { action } from 'typesafe-actions';

export const SyncActionTypes = {
  GET_CONNECT_AZUREAD_REQUEST: '@@sync/GET_CONNECT_AZUREAD_REQUEST',
  GET_GROUPS_AZUREAD_REQUEST: '@@sync/GET_GROUPS_AZUREAD_REQUEST',
  POST_SYNC_AZUREAD_REQUEST: '@@sync/POST_SYNC_AZUREAD_REQUEST',
  POST_SYNC_INTEGRATION_REQUEST: '@@sync/POST_SYNC_INTEGRATION_REQUEST',
  GET_SYNC_AZUREAD_REQUEST: '@@sync/GET_SYNC_AZUREAD_REQUEST',
  GET_LAST_SYNC_AZUREAD_REQUEST: '@@sync/GET_LAST_SYNC_AZUREAD_REQUEST',
  PUT_LDAP_SYNC_REQUEST: '@@sync/PUT_LDAP_SYNC_REQUEST',
  POST_TEST_LDAP_CONNECTION_REQUEST: '@@sync/POST_TEST_LDAP_CONNECTION_REQUEST',
  GET_LDAP_CONNECTIONS_REQUEST: '@@SYNC/GET_LDAP_CONNECTIONS_REQUEST',
  DELETE_LDAP_CONNECTION_REQUEST: '@@SYNC/DELETE_LDAP_CONNECTION_REQUEST',
  GET_LDAP_SCHEDULES_REQUEST: '@@SYNC/GET_LDAP_SCHEDULES_REQUEST',
  DELETE_LDAP_SCHEDULE_REQUEST: '@@SYNC/DELETE_LDAP_SCHEDULE_REQUEST',
  GET_RUN_LDAP_SCHEDULE_REQUEST: '@@SYNC/GET_RUN_LDAP_SCHEDULE_REQUEST',
  POST_LDAP_OUS_REQUEST: '@@SYNC/POST_LDAP_OUS_REQUEST',
  POST_LDAP_DETAILS_REQUEST: '@@SYNC/POST_LDAP_DETAILS_REQUEST',
  DELETE_LDAP_LOG_REQUEST: '@@SYNC/DELETE_LDAP_LOG_REQUEST',
  GET_LDAP_LOGS_REQUEST: '@@SYNC/GET_LDAP_LOGS_REQUEST',
  GET_AAD_TOKEN: '@@sync/GET_AAD_TOKEN',
};

export const getConnectAzureADRequest = (data) =>
  action(SyncActionTypes.GET_CONNECT_AZUREAD_REQUEST, data);
export const getGroupsAzureADRequest = (data) =>
  action(SyncActionTypes.GET_GROUPS_AZUREAD_REQUEST, data);
export const postSyncAzureADRequest = (data) =>
  action(SyncActionTypes.POST_SYNC_AZUREAD_REQUEST, data);
export const postSyncIntegrationRequest = (data) =>
  action(SyncActionTypes.POST_SYNC_INTEGRATION_REQUEST, data);
export const getSyncAzureADRequest = (data) =>
  action(SyncActionTypes.GET_SYNC_AZUREAD_REQUEST, data);
export const getLastSyncAzureADRequest = (data) =>
  action(SyncActionTypes.GET_LAST_SYNC_AZUREAD_REQUEST, data);
export const putLdapSyncRequest = (data) =>
  action(SyncActionTypes.PUT_LDAP_SYNC_REQUEST, data);
export const postTestLdapConnectionRequest = (data) =>
  action(SyncActionTypes.POST_TEST_LDAP_CONNECTION_REQUEST, data);
export const getLdapConnectionsRequest = (data) =>
  action(SyncActionTypes.GET_LDAP_CONNECTIONS_REQUEST, data);
export const deleteLdapConnectionRequest = (data) =>
  action(SyncActionTypes.DELETE_LDAP_CONNECTION_REQUEST, data);

// SCHEDULES
export const getLdapSchedulesRequest = (data) =>
  action(SyncActionTypes.GET_LDAP_SCHEDULES_REQUEST, data);
export const deleteLdapScheduleRequest = (data) =>
  action(SyncActionTypes.DELETE_LDAP_SCHEDULE_REQUEST, data);
export const getRunLdapScheduleRequest = (data) =>
  action(SyncActionTypes.GET_RUN_LDAP_SCHEDULE_REQUEST, data);

// LOG
export const getLdapLogsRequest = (data) =>
  action(SyncActionTypes.GET_LDAP_LOGS_REQUEST, data);
export const deleteLdapLogRequest = (data) =>
  action(SyncActionTypes.DELETE_LDAP_LOG_REQUEST, data);

/**
 * @deprecated
*/
export const postLdapOusRequest = (data) =>
  action(SyncActionTypes.POST_LDAP_OUS_REQUEST, data);
export const postLdapDetailsRequest = (data) =>
  action(SyncActionTypes.POST_LDAP_DETAILS_REQUEST, data);
export const getAadToken = (data) =>
  action(SyncActionTypes.GET_AAD_TOKEN, data);
