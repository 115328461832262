// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import sharedDevicesApis from 'apis/sharedDevicesApis';
// actions
import { SharedDevicesActionTypes } from 'actions/sharedDeviceActions';

export function* handleGetSharedDevices({ payload }) {
  try {
    const response = yield call(
      sharedDevicesApis.getSharedDevicesRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteSharedDevices({ payload }) {
  try {
    const response = yield call(sharedDevicesApis.deleteSharedDevices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* sharedDevicesSaga() {
  yield all([
    yield takeEvery(
      SharedDevicesActionTypes.GET_SHARED_DEVICES_REQUEST,
      handleGetSharedDevices,
    ),
    yield takeEvery(
      SharedDevicesActionTypes.DELETE_SHARED_DEVICES_REQUEST,
      handleDeleteSharedDevices,
    ),
  ]);
}

export default sharedDevicesSaga;
