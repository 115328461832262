// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import securityKeyPolicyApi from 'apis/securityKeyPolicyApi';
// actions
import { SecurityKeyPolicyActionTypes } from 'actions/securityKeyPolicyActions';

export function* handlePostAddNewPolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.postAddNewPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllPolicies({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.getAllPolicies, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeletePolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.deletePolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutDefaultPolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.putDefaultPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDefaultPolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.getDefaultPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetUsers({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.getUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetGroups({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.getGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetPolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.getPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleEditPolicy({ payload }) {
  try {
    const response = yield call(securityKeyPolicyApi.editPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetUsedPriorities({ payload }) {
  try {
    const response = yield call(
      securityKeyPolicyApi.getUsedPriorities,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

function* securityKeyPolicySaga() {
  yield all([
    yield takeEvery(
      SecurityKeyPolicyActionTypes.POST_ADD_POLICY,
      handlePostAddNewPolicy,
    ),
    yield takeEvery(
      SecurityKeyPolicyActionTypes.GET_ALL_POLICIES,
      handleGetAllPolicies,
    ),
    yield takeEvery(
      SecurityKeyPolicyActionTypes.DELETE_POLICY,
      handleDeletePolicy,
    ),
    yield takeEvery(
      SecurityKeyPolicyActionTypes.PUT_DEFAULT_POLICY,
      handlePutDefaultPolicy,
    ),
    yield takeEvery(
      SecurityKeyPolicyActionTypes.GET_DEFAULT_POLICY,
      handleGetDefaultPolicy,
    ),
    yield takeEvery(SecurityKeyPolicyActionTypes.GET_USERS, handleGetUsers),
    yield takeEvery(SecurityKeyPolicyActionTypes.GET_GROUPS, handleGetGroups),
    yield takeEvery(SecurityKeyPolicyActionTypes.GET_POLICY, handleGetPolicy),
    yield takeEvery(SecurityKeyPolicyActionTypes.EDIT_POLICY, handleEditPolicy),
    yield takeEvery(
      SecurityKeyPolicyActionTypes.GET_USED_PRIORITIES,
      handleGetUsedPriorities,
    ),
  ]);
}

export default securityKeyPolicySaga;
