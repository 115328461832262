import { notification } from 'antd';
import i18n from "../i18n"

const successNotif = (message) => {
  notification['success']({
    message: i18n.t('SUCCESS'),
    description: message || i18n.t('SUCCESS'),
    duration: 10,
    key: 'success',
    style: { background: '#E5FFF0' },
  });
};

const resetErrorNotif = (url) => {
  notification.close(`error-${url}`);
};

const errorNotif = (message, close, url, title) => {
  if (close) notification.close('error');
  else
    notification['error']({
      message: title || i18n.t('ERROR'),
      description: message || i18n.t('ERROR'),
      duration: 15,
      key: url ? `error-${url}` : 'error',
      style: { background: '#FFE5E7' },
    });
};

const warningNotif = (message, close) => {
  if (close) {
    notification.destroy('');
  } else
    notification['warning']({
      message: i18n.t('WARNING'),
      description: message || i18n.t('WARNING'),
      duration: 15,
      key: 'warning',
      style: { background: '#FFF4E5' },
    });
};

const infoNotif = (message, title) => {
  notification['info']({
    message: title || i18n.t('Info'),
    description: message || i18n.t('Info'),
    duration: 15,
    key: 'info',
  });
};

export { successNotif, errorNotif, warningNotif, resetErrorNotif, infoNotif };
