import { action } from 'typesafe-actions';

export const TokenPolicyActionTypes = {
  GET_TOKEN_POLICY_REQUEST: '@@tokenPolicy/GET_TOKEN_POLICY_REQUEST',
  POST_TOKEN_POLICY_REQUEST: '@@tokenPolicy/POST_TOKEN_POLICY_REQUEST',
  PUT_DEFAULT_TOKEN_POLICY_REQUEST:
    '@@tokenPolicy/PUT_DEFAULT_TOKEN_POLICY_REQUEST',
  GET_DEFAULT_TOKEN_POLICY_REQUEST:
    '@@tokenPolicy/GET_DEFAULT_TOKEN_POLICY_REQUEST',
};

export const getTokenPolicyRequest = (data) =>
  action(TokenPolicyActionTypes.GET_TOKEN_POLICY_REQUEST, data);
export const postTokenPolicyRequest = (data) =>
  action(TokenPolicyActionTypes.POST_TOKEN_POLICY_REQUEST, data);
export const getDefaultTokenPolicyRequest = (data) =>
  action(TokenPolicyActionTypes.GET_DEFAULT_TOKEN_POLICY_REQUEST, data);
export const putDefaultTokenPolicyRequest = (data) =>
  action(TokenPolicyActionTypes.PUT_DEFAULT_TOKEN_POLICY_REQUEST, data);
