import { action } from 'typesafe-actions';

export const SecurityKeyPolicyActionTypes = {
  POST_ADD_POLICY: '@@SecurityKeyPolicy/POST_ADD_POLICY',
  DELETE_POLICY: '@@SecurityKeyPolicy/DELETE_POLICY',
  GET_ALL_POLICIES: '@@SecurityKeyPolicy/GET_ALL_POLICIES',
  PUT_DEFAULT_POLICY: '@@SecurityKeyPolicy/PUT_DEFAULT_POLICY',
  GET_DEFAULT_POLICY: '@@SecurityKeyPolicy/GET_DEFAULT_POLICY',
  GET_USERS: '@@SecurityKeyPolicy/GET_USERS',
  GET_GROUPS: '@@SecurityKeyPolicy/GET_GROUPS',
  GET_POLICY: '@@SecurityKeyPolicy/GET_POLICY',
  EDIT_POLICY: '@@SecurityKeyPolicy/EDIT_POLICY',
  GET_USED_PRIORITIES: '@@SecurityKeyPolicy/GET_USED_PRIORITIES',
};

export const postAddPolicyRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.POST_ADD_POLICY, data);

export const getAllPoliciesRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_ALL_POLICIES, data);
export const deletePolicy = (data) =>
  action(SecurityKeyPolicyActionTypes.DELETE_POLICY, data);
export const putDefaultPolicy = (data) =>
  action(SecurityKeyPolicyActionTypes.PUT_DEFAULT_POLICY, data);
export const getDefaultPolicy = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_DEFAULT_POLICY, data);
export const getUsersRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_USERS, data);
export const getGroupsRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_GROUPS, data);
export const getPolicyRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_POLICY, data);
export const editPolicyRequest = (data) =>
  action(SecurityKeyPolicyActionTypes.EDIT_POLICY, data);
export const getUsedPriorities = (data) =>
  action(SecurityKeyPolicyActionTypes.GET_USED_PRIORITIES, data);
