// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import authenticationApis from 'apis/authenticationApis';
// actions
import { AuthenticationActionTypes } from 'actions/authenticationActions';
import { errorNotif } from 'utils/notification';

export function* handlePostDelegatedAuthenticate({ payload }) {
  try {
    const response = yield call(
      authenticationApis.postDelegatedAuthenticate,
      payload,
    );
    errorNotif('', true);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* authenticationSaga() {
  yield all([
    yield takeEvery(
      AuthenticationActionTypes.POST_DELEGATED_AUTHENTICATION_REQUEST,
      handlePostDelegatedAuthenticate,
    ),
  ]);
}

export default authenticationSaga;
