// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import SSO_Apis from 'apis/ssoApis';
// actions
import { SSOActionTypes } from 'actions/SSOAction';

export function* handleActiveServices({ payload }) {
  try {
    const response = yield call(SSO_Apis.activeServices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleAvailableServices({ payload }) {
  try {
    const response = yield call(SSO_Apis.availableServices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleServiceProviders({ payload }) {
  try {
    const response = yield call(SSO_Apis.serviceProviders, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleServiceConfigs({ payload }) {
  try {
    const response = yield call(SSO_Apis.serviceConfigs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteService({ payload }) {
  try {
    const response = yield call(SSO_Apis.deleteService, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetServiceDocs({ payload }) {
  try {
    const response = yield call(SSO_Apis.getServiceDocs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetScimDocs({ payload }) {
  try {
    const response = yield call(SSO_Apis.getScimDocs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetAppd({ payload }) {
  try {
    const response = yield call(SSO_Apis.getAppd, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleApp({ payload }) {
  try {
    const response = yield call(SSO_Apis.app, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetServiceGroups({ payload }) {
  try {
    const response = yield call(SSO_Apis.getServiceGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* allUserSaga() {
  yield all([
    yield takeEvery(
      SSOActionTypes.ACTIVE_SERVICES_REQUEST,
      handleActiveServices,
    ),
    yield takeEvery(
      SSOActionTypes.AVAILABLE_SERVICES_REQUEST,
      handleAvailableServices,
    ),
    yield takeEvery(
      SSOActionTypes.SERVICE_PROVIDERS_REQUEST,
      handleServiceProviders,
    ),
    yield takeEvery(
      SSOActionTypes.SERVICE_CONFIGS_REQUEST,
      handleServiceConfigs,
    ),
    yield takeEvery(SSOActionTypes.DELETE_SERVICE_REQUEST, handleDeleteService),
    yield takeEvery(
      SSOActionTypes.GET_SERVICE_DOCS_REQUEST,
      handleGetServiceDocs,
    ),
    yield takeEvery(SSOActionTypes.GET_SCIM_DOCS_REQUEST, handleGetScimDocs),
    yield takeEvery(SSOActionTypes.GET_APPD_REQUEST, handleGetAppd),
    yield takeEvery(SSOActionTypes.APP_REQUEST, handleApp),
    yield takeEvery(SSOActionTypes.GET_SERVICE_GROUPS, handleGetServiceGroups),
  ]);
}

export default allUserSaga;
