// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import downloadApis from 'apis/downloadApis';
// actions
import { DownloadActionTypes } from 'actions/downloadActions';

export function* handlePostNewReader({ payload }) {
  try {
    const response = yield call(downloadApis.postNewReaderRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDrivers({ payload }) {
  try {
    const response = yield call(downloadApis.getDriversRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* downloadSaga() {
  yield all([
    yield takeEvery(
      DownloadActionTypes.POST_NEW_READER_REQUEST,
      handlePostNewReader,
    ),
    yield takeEvery(DownloadActionTypes.GET_DRIVERS_REQUEST, handleGetDrivers),
  ]);
}

export default downloadSaga;
