import { action } from 'typesafe-actions';

export const SessionActionTypes = {
  GET_MY_SESSIONS_REQUEST: '@@sessionActoins/GET_MY_SESSIONS_REQUEST',
  TERMINATE_MY_SESSION_REQUEST: '@@sessionActoins/TERMINATE_MY_SESSION_REQUEST',
};

export const getMySessionsRequest = (data) =>
  action(SessionActionTypes.GET_MY_SESSIONS_REQUEST, data);

export const terminateMySessionRequest = (data) =>
  action(SessionActionTypes.TERMINATE_MY_SESSION_REQUEST, data);
