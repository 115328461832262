// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import paymentConfirmationApis from 'apis/paymentConfirmation';
// actions
import { paymentConfirmActionTypes } from 'actions/paymentConfirmAction';

export function* handlePostCardRequest({ payload }) {
  try {
    const response = yield call(
      paymentConfirmationApis.addCardRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetCardRequest({ payload }) {
  try {
    const response = yield call(
      paymentConfirmationApis.getCardsRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteCardRequest({ payload }) {
  try {
    const response = yield call(
      paymentConfirmationApis.deleteCardsRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleAddConfirmRequest({ payload }) {
  try {
    const response = yield call(
      paymentConfirmationApis.addConfirmRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetConfirmRequest({ payload }) {
  try {
    const response = yield call(
      paymentConfirmationApis.getConfirmRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* paymentConfirmSaga() {
  yield all([
    yield takeEvery(
      paymentConfirmActionTypes.POST_CARDS,
      handlePostCardRequest,
    ),
    yield takeEvery(paymentConfirmActionTypes.GET_CARDS, handleGetCardRequest),
    yield takeEvery(
      paymentConfirmActionTypes.DELETE_CARDS,
      handleDeleteCardRequest,
    ),
    yield takeEvery(
      paymentConfirmActionTypes.ADD_CONFIRM,
      handleAddConfirmRequest,
    ),
    yield takeEvery(
      paymentConfirmActionTypes.GET_CONFIRM,
      handleGetConfirmRequest,
    ),
  ]);
}

export default paymentConfirmSaga;
