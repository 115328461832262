// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import billingApis from 'apis/billingApis';
// actions
import { BillingActionTypes } from 'actions/billingActions';

export function* handleGetBillingStatus({ payload }) {
  try {
    const response = yield call(billingApis.getBillingStatus, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetBillingHistory({ payload }) {
  try {
    const response = yield call(billingApis.getBillingHistory, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetBillingPolicy({ payload }) {
  try {
    const response = yield call(billingApis.getBillingPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetOneBillCSV({ payload }) {
  try {
    const response = yield call(billingApis.getOneBillCSV, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostAddCreditCard({ payload }) {
  try {
    const response = yield call(billingApis.postAddCreditCard, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetAllCreditCards({ payload }) {
  try {
    const response = yield call(billingApis.getAllCreditCards, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteCreditCard({ payload }) {
  try {
    const response = yield call(billingApis.deleteCreditCard, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleEditCreditCard({ payload }) {
  try {
    const response = yield call(billingApis.editCreditCard, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleEditBillingAddress({ payload }) {
  try {
    const response = yield call(billingApis.editBillingAddress, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutCheckoutOverdueInvoice({ payload }) {
  try {
    const response = yield call(billingApis.putCheckoutOverdueInvoice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostAnnualSubscriptionRequest({ payload }) {
  try {
    const response = yield call(
      billingApis.postAnnualSubscriptionRequest,
      payload,
    );
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* tokenPolicySaga() {
  yield all([
    yield takeEvery(
      BillingActionTypes.GET_BILLING_STATUS,
      handleGetBillingStatus,
    ),
    yield takeEvery(
      BillingActionTypes.GET_BILLING_HISTORY,
      handleGetBillingHistory,
    ),
    yield takeEvery(
      BillingActionTypes.GET_BILLING_POLICY,
      handleGetBillingPolicy,
    ),
    yield takeEvery(BillingActionTypes.GET_ONE_BILL_CSV, handleGetOneBillCSV),
    yield takeEvery(
      BillingActionTypes.POST_ADD_CREDIT_CARD,
      handlePostAddCreditCard,
    ),
    yield takeEvery(
      BillingActionTypes.GET_ALL_CREDIT_CARDS,
      handleGetAllCreditCards,
    ),
    yield takeEvery(
      BillingActionTypes.DELETE_CREDIT_CARD,
      handleDeleteCreditCard,
    ),
    yield takeEvery(BillingActionTypes.EDIT_CREDIT_CARD, handleEditCreditCard),
    yield takeEvery(
      BillingActionTypes.EDIT_BILLING_ADDRESS,
      handleEditBillingAddress,
    ),
    yield takeEvery(
      BillingActionTypes.PUT_CHECKOUT_OVERDUE_INVOICE,
      handlePutCheckoutOverdueInvoice,
    ),
    yield takeEvery(
      BillingActionTypes.POST_ANNUAL_SUBSCRIPTION_REQUEST,
      handlePostAnnualSubscriptionRequest,
    ),
  ]);
}

export default tokenPolicySaga;
