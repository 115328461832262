import React from 'react';
import { Spin } from 'antd';
import 'styles/custom.css';

const SuspenseSpin = () => {
  return (
    <div className="spin-style-suspense__parent">
      <Spin className="spin-style-suspense" />
    </div>
  );
};

export default SuspenseSpin;
