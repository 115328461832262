import Dispatch from './client';

const urls = {
  status: {
    method: 'get',
    url: '/administrator/bills/current',
  },
  history: {
    method: 'get',
    url: '/administrator/bills/history',
  },
  policy: {
    method: 'get',
    url: '/administrator/bills/billingPolicy',
  },
  oneBillCSV: (id) => {
    return { method: 'get', url: `/administrator/bills/${id}/invoice` };
  },
  addCreditCard: {
    method: 'post',
    url: '/administrator/payment/credit-cards/information',
  },
  getAllCreditCards: {
    method: 'get',
    url: '/administrator/payment/credit-cards/information',
  },
  deleteCreditCard: (cardId) => {
    return {
      method: 'delete',
      url: `/administrator/payment/credit-cards/${cardId}/information`,
    };
  },
  editCreditCard: (cardId) => {
    return {
      method: 'put',
      url: `/administrator/payment/credit-cards/${cardId}/information`,
    };
  },
  editBillingAddress: (cardId) => {
    return {
      method: 'put',
      url: `/administrator/payment/credit-cards/${cardId}/billing-address`,
    };
  },
  putCheckoutOverdueInvoice: (invoiceId) => {
    return { method: 'put', url: `/administrator/bills/${invoiceId}/checkout` };
  },
  postAnnualSubscriptionRequest: {
    method: 'post',
    url: '/administrator/support/annual-request',
  },
};

function api() {
  return {
    getBillingStatus: (data) =>
      Dispatch(urls.status, data.params, data.payload),
    getBillingHistory: (data) =>
      Dispatch(urls.history, data.params, data.payload),
    getBillingPolicy: (data) =>
      Dispatch(urls.policy, data.params, data.payload),
    getOneBillCSV: (data) =>
      Dispatch(urls.oneBillCSV(data.values._id), data.params, data.payload),
    postAddCreditCard: (data) =>
      Dispatch(urls.addCreditCard, data.params, data.payload),
    getAllCreditCards: (data) =>
      Dispatch(urls.getAllCreditCards, data.params, data.payload),
    deleteCreditCard: (data) =>
      Dispatch(
        urls.deleteCreditCard(data.values.cardId),
        data.params,
        data.payload,
      ),
    editCreditCard: (data) =>
      Dispatch(urls.editCreditCard(data.cardId), data.params, data.payload),
    editBillingAddress: (data) =>
      Dispatch(urls.editBillingAddress(data.cardId), data.params, data.payload),
    putCheckoutOverdueInvoice: (data) =>
      Dispatch(
        urls.putCheckoutOverdueInvoice(data.invoiceId),
        data.params,
        data.payload,
      ),
    postAnnualSubscriptionRequest: (data) =>
      Dispatch(urls.postAnnualSubscriptionRequest, data.params, data.payload),
  };
}

export default api();
