import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import workspaceApis from 'apis/workspaceApi';
// actions
import { WorkspaceActionTypes } from 'actions/workspaceActions';

export function* handleGet({ payload }) {
  try {
    const response = yield call(workspaceApis.getAdministrators, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePost({ payload }) {
  try {
    const response = yield call(workspaceApis.postNewAdministrator, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDelete({ payload }) {
  try {
    const response = yield call(workspaceApis.deleteAdministrator, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutProximity({ payload }) {
  try {
    const response = yield call(workspaceApis.putProximity, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetActiveUsers({ payload }) {
  try {
    const response = yield call(workspaceApis.getActiveUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* workspaceSaga() {
  yield all([
    yield takeEvery(WorkspaceActionTypes.GET_ADMINISTRATORS_REQUEST, handleGet),
    yield takeEvery(
      WorkspaceActionTypes.POST_ADMINISTRATOR_REQUEST,
      handlePost,
    ),
    yield takeEvery(
      WorkspaceActionTypes.DELETE_ADMINISTRATOR_REQUEST,
      handleDelete,
    ),
    yield takeEvery(
      WorkspaceActionTypes.PUT_PROXIMITY_REQUEST,
      handlePutProximity,
    ),
    yield takeEvery(
      WorkspaceActionTypes.GET_ACTIVE_USERS_REQUEST,
      handleGetActiveUsers,
    ),
  ]);
}

export default workspaceSaga;
