import { action } from 'typesafe-actions';

export const GroupActionTypes = {
  GET_GROUP_REQUEST: '@@groups/GET_GROUP_REQUEST',
  GET_USERS_FOR_ASSIGN_GROUP: '@@groups/GET_USERS_FOR_ASSIGN_GROUP',
  POST_GROUP_REQUEST: '@@groups/POST_GROUP_REQUEST',
  PUT_USER_IN_GROUP_REQUEST: '@@groups/PUT_USER_IN_GROUP_REQUEST',
  PUT_ASSIGN_SECURITY_KEY: '@@groups/PUT_ASSIGN_SECURITY_KEY',
  GET_ONE_GROUP_DATA: '@@groups/GET_ONE_GROUP_DATA',
  PUT_EDIT_ONE_GROUP: '@@groups/PUT_EDIT_ONE_GROUP',
  PUT_EDIT_ONE_GROUP_INFO: '@@groups/PUT_EDIT_ONE_GROUP_INFO',
  DELETE_ONE_GROUP: '@@groups/DELETE_ONE_GROUP',
  PUT_SUSPEND_MANY_GROUPS: '@@groups/PUT_SUSPEND_MANY_GROUPS',
  POST_ADD_GROUP_USERS: '@@groups/POST_ADD_GROUP_USERS',
  DELETE_GROUP_USERS: '@@groups/DELETE_GROUP_USERS',
};

export const getGroupRequest = (data) =>
  action(GroupActionTypes.GET_GROUP_REQUEST, data);
export const getUserForAssignInGroupRequest = (data) =>
  action(GroupActionTypes.GET_USERS_FOR_ASSIGN_GROUP, data);
export const postGroupRequest = (data) =>
  action(GroupActionTypes.POST_GROUP_REQUEST, data);
export const putAssignSecurityKey = (data) =>
  action(GroupActionTypes.PUT_ASSIGN_SECURITY_KEY, data);
export const putUsersInGroupRequest = (data) =>
  action(GroupActionTypes.PUT_USER_IN_GROUP_REQUEST, data);
export const getOneGroupDataRequest = (data) =>
  action(GroupActionTypes.GET_ONE_GROUP_DATA, data);
export const putEditOneGroupRequest = (data) =>
  action(GroupActionTypes.PUT_EDIT_ONE_GROUP, data);
export const putEditOneGroupInfoRequest = (data) =>
  action(GroupActionTypes.PUT_EDIT_ONE_GROUP_INFO, data);
export const deleteOneGroupRequest = (data) =>
  action(GroupActionTypes.DELETE_ONE_GROUP, data);
export const putSuspendManyGroupsRequest = (data) =>
  action(GroupActionTypes.PUT_SUSPEND_MANY_GROUPS, data);
export const postAddGroupUsersRequest = (data) =>
  action(GroupActionTypes.POST_ADD_GROUP_USERS, data);
export const deleteGroupUsersRequest = (data) =>
  action(GroupActionTypes.DELETE_GROUP_USERS, data);
