import { action } from 'typesafe-actions';

export const WorkspaceActionTypes = {
  GET_ADMINISTRATORS_REQUEST: '@@workspace/GET_ADMINISTRATORS_REQUEST',
  POST_ADMINISTRATOR_REQUEST: '@@workspace/POST_ADMINISTRATOR_REQUEST',
  DELETE_ADMINISTRATOR_REQUEST: '@@workspace/DELETE_ADMINISTRATOR_REQUEST',
  PUT_PROXIMITY_REQUEST: '@@workspace/PUT_PROXIMITY_REQUEST',
  GET_ACTIVE_USERS_REQUEST: '@@workspace/GET_ACTIVE_USERS_REQUEST',
};

export const getAdministratorsRequest = (data) =>
  action(WorkspaceActionTypes.GET_ADMINISTRATORS_REQUEST, data);
export const postAdministratorsRequest = (data) =>
  action(WorkspaceActionTypes.POST_ADMINISTRATOR_REQUEST, data);
export const deleteAdministratorsRequest = (data) =>
  action(WorkspaceActionTypes.DELETE_ADMINISTRATOR_REQUEST, data);
export const putProximityRequest = (data) =>
  action(WorkspaceActionTypes.PUT_PROXIMITY_REQUEST, data);
export const getActiveUsersRequest = (data) =>
  action(WorkspaceActionTypes.GET_ACTIVE_USERS_REQUEST, data);
