// node modules
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
// apis
import syncApis from 'apis/syncApis';
// actions
import { SyncActionTypes } from 'actions/syncActions';

export function* handleGetConnectAzureAD({ payload }) {
  try {
    const response = yield call(syncApis.getConnectToAzureAD, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetGroupsAzureAD({ payload }) {
  try {
    const response = yield call(syncApis.getGroupsAzureAD, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostSyncAzureAD({ payload }) {
  try {
    const response = yield call(syncApis.postSyncAzureAD, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostSyncIntegration({ payload }) {
  try {
    const response = yield call(syncApis.postSyncIntegration, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetSyncAzureAD({ payload }) {
  try {
    const response = yield call(syncApis.getSyncAzureAD, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleLastGetSyncAzureAD({ payload }) {
  try {
    const response = yield call(syncApis.getLastSyncAzureAD, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutLdapSync({ payload }) {
  try {
    const response = yield call(syncApis.putLdapSync, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlegetAadToken({ payload }) {
  try {
    const response = yield call(syncApis.getTokenAad, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* testLDAPConnection({ payload }) {
  try {
    const response = yield call(syncApis.testLDAP, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* LDAPConnections({ payload }) {
  try {
    const response = yield call(syncApis.LdapConfigs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* DeleteLDAPConnection({ payload }) {
  try {
    const response = yield call(syncApis.DeleteLdapConfig, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* LDAPOus({ payload }) {
  try {
    const response = yield call(syncApis.LdapOUs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* LDAPDetails({ payload }) {
  try {
    const response = yield call(syncApis.LdapDetails, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}


export function* RunSchedule({ payload }) {
  try {
    const response = yield call(syncApis.GetRunSchedule, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* DeleteSchedule({ payload }) {
  try {
    const response = yield call(syncApis.DeleteSchedule, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* GetSchedules({ payload }) {
  try {
    const response = yield call(syncApis.GetSchedules, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* DeleteLog({ payload }) {
  try {
    const response = yield call(syncApis.DeleteLog, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* GetLogs({ payload }) {
  try {
    const response = yield call(syncApis.GetLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* licenseSaga() {
  yield all([
    yield takeEvery(
      SyncActionTypes.GET_CONNECT_AZUREAD_REQUEST,
      handleGetConnectAzureAD,
    ),
    yield takeLatest(
      SyncActionTypes.GET_GROUPS_AZUREAD_REQUEST,
      handleGetGroupsAzureAD,
    ),
    yield takeEvery(
      SyncActionTypes.POST_SYNC_AZUREAD_REQUEST,
      handlePostSyncAzureAD,
    ),
    yield takeEvery(
      SyncActionTypes.POST_SYNC_INTEGRATION_REQUEST,
      handlePostSyncIntegration,
    ),
    yield takeEvery(
      SyncActionTypes.GET_SYNC_AZUREAD_REQUEST,
      handleGetSyncAzureAD,
    ),
    yield takeEvery(
      SyncActionTypes.GET_LAST_SYNC_AZUREAD_REQUEST,
      handleLastGetSyncAzureAD,
    ),
    yield takeEvery(SyncActionTypes.PUT_LDAP_SYNC_REQUEST, handlePutLdapSync),
    yield takeEvery(SyncActionTypes.POST_TEST_LDAP_CONNECTION_REQUEST, testLDAPConnection),
    yield takeEvery(SyncActionTypes.GET_LDAP_CONNECTIONS_REQUEST, LDAPConnections),
    yield takeEvery(SyncActionTypes.DELETE_LDAP_CONNECTION_REQUEST, DeleteLDAPConnection),
    yield takeEvery(SyncActionTypes.POST_LDAP_DETAILS_REQUEST, LDAPDetails),
    yield takeEvery(SyncActionTypes.POST_LDAP_OUS_REQUEST, LDAPOus),
    yield takeEvery(SyncActionTypes.GET_AAD_TOKEN, handlegetAadToken),

    yield takeEvery(SyncActionTypes.GET_LDAP_SCHEDULES_REQUEST, GetSchedules),
    yield takeEvery(SyncActionTypes.DELETE_LDAP_SCHEDULE_REQUEST, DeleteSchedule),
    yield takeEvery(SyncActionTypes.GET_RUN_LDAP_SCHEDULE_REQUEST, RunSchedule),

    yield takeEvery(SyncActionTypes.GET_LDAP_LOGS_REQUEST, GetLogs),
    yield takeEvery(SyncActionTypes.DELETE_LDAP_LOG_REQUEST, DeleteLog),
  ]);
}

export default licenseSaga;
