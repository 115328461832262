import { action } from 'typesafe-actions';

export const AdminActionTypes = {
  GET_RP_REQUEST: '@@admin/GET_RP_REQUEST',
  GET_CUSTOMER_REQUEST: '@@admin/GET_CUSTOMER_REQUEST',
  GET_WORKSPACE_ADMINS_INFO: '@@admin/GET_WORKSPACE_ADMINS_INFO',
  GET_FILTERED_WORKSPACE_ADMINS_INFO:
    '@@admin/GET_FILTERED_WORKSPACE_ADMINS_INFO',
  POST_ADDLICENSE_REQUEST: '@@admin/POST_ADDLICENSE_REQUEST',
  GET_LICENSE_LIST_REQUEST: '@@admin/GET_LICENSE_LIST_REQUEST',
  ACTION_LICENSE_REQUEST: '@@admin/ACTION_LICENSE_REQUEST',

  POST_CHECK_WORKSPACE_ID_REQUEST: '@@admin/POST_CHECK_WORKSPACE_ID_REQUEST',
  PUT_UPGRADE_WORKSPACE_BUSINESS_REQUEST:
    '@@admin/PUT_UPGRADE_WORKSPACE_BUSINESS_REQUEST',

  POST_DOMAIN: '@@admin/POST_DOMAIN',
  GET_DOMAIN: '@@admin/GET_DOMAIN',
  DELETE_DOMAIN: '@@admin/DELETE_DOMAIN',

  GET_INDIVIDUAL_TOKENS: '@@admin/GET_INDIVIDUAL_TOKENS',
  POST_INDIVIDUAL_TOKENS: '@@admin/POST_INDIVIDUAL_TOKENS',

  GET_SECURITY_KEYS: '@@admin/GET_SECURITY_KEYS',
  GET_SECURITY_KEYS_LOGS: '@@admin/GET_SECURITY_KEYS_LOGS',

  GET_TRUST_DOMAINS_REQUEST: '@@admin/GET_TRUST_DOMAINS_REQUEST',
  PUT_ACTION_ON_TRUST_DOMAIN_REQUEST:
    '@@admin/PUT_ACTION_ON_TRUST_DOMAIN_REQUEST',
  GET_TOKENS: '@@admin/GET_TOKENS',
  PUT_SPECIAL_WORKSPACE: '@@admin/PUT_SPECIAL_WORKSPACE',
  GET_PAIRED_LIST: '@@admin/PAIRED_LIST',
  DELETE_WORKSPACE: '@@admin/DELETE_WORKSPACE',
  GET_EXTRA_ACTIVITY_LOGS: '@@admin/GET_EXTRA_ACTIVITY_LOGS',
  GET_TOKEN_POLICY: '@@admin/GET_TOKEN_POLICY',
  GET_BILLING_INFORMATION: '@@admin/GET_BILLING_INFORMATION',
  PUT_BILLING_POLICY: '@@admin/PUT_BILLING_POLICY',
  GET_BILLING_POLICY: '@@admin/GET_BILLING_POLICY',
  PUT_WORKSPACE_STATUS: '@@admin/PUT_WORKSPACE_STATUS',
  GET_WORKSPACE_INVOICE_HISTORY: '@@admin/GET_WORKSPACE_INVOICE_HISTORY',
  PUT_PAY_WORKSPACE_OVERDUE_INVOICE:
    '@@admin/PUT_PAY_WORKSPACE_OVERDUE_INVOICE',
  POST_ISSUE_NEW_INVOICE: '@@admin/POST_ISSUE_NEW_INVOICE',
  POST_VIEW_SAMPLE_INVOICE: '@@admin/POST_VIEW_SAMPLE_INVOICE',
  GET_CURRENT_INVOICE: '@@admin/GET_CURRENT_INVOICE',
  PUT_CHANGE_PAYMENT_METHOD: '@@admin/PUT_CHANGE_PAYMENT_METHOD',
  GET_WORKSPACE_INVOICE: '@@admin/GET_WORKSPACE_INVOICE',
  PUT_SET_PAID_STATUS: '@@admin/PUT_SET_PAID_STATUS',
  GET_DEFAULT_BILLING_POLICY: '@@admin/GET_DEFAULT_BILLING_POLICY',
  PUT_PROFILE_INFO: '@@admin/PUT_PROFILE_INFO',
  DELETE_ONE_INVOICE: '@@admin/DELETE_ONE_INVOICE',
  PUT_SPECIAL_CONFIG: '@@admin/PUT_SPECIAL_CONFIG',
  POST_IMPORT_BULK: '@@admin/POST_IMPORT_BULK',
};

export const getRpRequest = (data) =>
  action(AdminActionTypes.GET_RP_REQUEST, data);

export const getAdministratorsRequest = (data) =>
  action(AdminActionTypes.GET_CUSTOMER_REQUEST, data);
export const getWorkspaceAdministrators = (data) =>
  action(AdminActionTypes.GET_WORKSPACE_ADMINS_INFO, data);
export const getFilteredWorkspaceAdministrationInfo = (data) =>
  action(AdminActionTypes.GET_FILTERED_WORKSPACE_ADMINS_INFO, data);
export const postAddLicenseRequest = (data) =>
  action(AdminActionTypes.POST_ADDLICENSE_REQUEST, data);
export const getLicenseListRequest = (data) =>
  action(AdminActionTypes.GET_LICENSE_LIST_REQUEST, data);

export const actionLicenseRequest = (data) =>
  action(AdminActionTypes.ACTION_LICENSE_REQUEST, data);

export const postCheckWorkspaceIdRequest = (data) =>
  action(AdminActionTypes.POST_CHECK_WORKSPACE_ID_REQUEST, data);
export const putUpgradeWorkspaceRequest = (data) =>
  action(AdminActionTypes.PUT_UPGRADE_WORKSPACE_BUSINESS_REQUEST, data);
export const postDomain = (data) => action(AdminActionTypes.POST_DOMAIN, data);
export const getDomain = (data) => action(AdminActionTypes.GET_DOMAIN, data);
export const deleteDomain = (data) =>
  action(AdminActionTypes.DELETE_DOMAIN, data);
export const getIndividualTokens = (data) =>
  action(AdminActionTypes.GET_INDIVIDUAL_TOKENS, data);
export const postIndividualTokens = (data) =>
  action(AdminActionTypes.POST_INDIVIDUAL_TOKENS, data);
export const getSecurityKeys = (data) =>
  action(AdminActionTypes.GET_SECURITY_KEYS, data);
export const getSecurityKeysLogs = (data) =>
  action(AdminActionTypes.GET_SECURITY_KEYS_LOGS, data);
export const getTrustDomainsRequest = (data) =>
  action(AdminActionTypes.GET_TRUST_DOMAINS_REQUEST, data);
export const putActionOnTrustDomainsRequest = (data) =>
  action(AdminActionTypes.PUT_ACTION_ON_TRUST_DOMAIN_REQUEST, data);

export const getTokens = (data) => action(AdminActionTypes.GET_TOKENS, data);
export const putSpecialWorkspace = (data) =>
  action(AdminActionTypes.PUT_SPECIAL_WORKSPACE, data);
export const getPairedList = (data) =>
  action(AdminActionTypes.GET_PAIRED_LIST, data);
export const deleteWorkspace = (data) =>
  action(AdminActionTypes.DELETE_WORKSPACE, data);
export const getExtraActivityLogs = (data) =>
  action(AdminActionTypes.GET_EXTRA_ACTIVITY_LOGS, data);
export const getAllTokenPolicy = (data) =>
  action(AdminActionTypes.GET_TOKEN_POLICY, data);
export const getBillingInformation = (data) =>
  action(AdminActionTypes.GET_BILLING_INFORMATION, data);
export const putBillingPolicy = (data) =>
  action(AdminActionTypes.PUT_BILLING_POLICY, data);
export const getBillingPolicyRequest = (data) =>
  action(AdminActionTypes.GET_BILLING_POLICY, data);
export const putWorkspaceStatus = (data) =>
  action(AdminActionTypes.PUT_WORKSPACE_STATUS, data);
export const getWorkspaceInvoiceHistoryRequest = (data) =>
  action(AdminActionTypes.GET_WORKSPACE_INVOICE_HISTORY, data);
export const putPayWorkspaceOverdueInvoiceRequest = (data) =>
  action(AdminActionTypes.PUT_PAY_WORKSPACE_OVERDUE_INVOICE, data);
export const postIssueNewInvoiceRequest = (data) =>
  action(AdminActionTypes.POST_ISSUE_NEW_INVOICE, data);
export const postViewSampleInvoiceRequest = (data) =>
  action(AdminActionTypes.POST_VIEW_SAMPLE_INVOICE, data);
export const getCurrentInvoiceRequest = (data) =>
  action(AdminActionTypes.GET_CURRENT_INVOICE, data);
export const putChangePaymentMethod = (data) =>
  action(AdminActionTypes.PUT_CHANGE_PAYMENT_METHOD, data);
export const getWorkspaceInvoiceRequest = (data) =>
  action(AdminActionTypes.GET_WORKSPACE_INVOICE, data);
export const putSetPaidStatusRequest = (data) =>
  action(AdminActionTypes.PUT_SET_PAID_STATUS, data);
export const getDefaultBillingPolicyRequest = (data) =>
  action(AdminActionTypes.GET_DEFAULT_BILLING_POLICY, data);
export const updateProfileInfoRequest = (data) =>
  action(AdminActionTypes.PUT_PROFILE_INFO, data);
export const deleteOneInvoiceRequest = (data) =>
  action(AdminActionTypes.DELETE_ONE_INVOICE, data);
export const putSpecialConfigRequest = (data) =>
  action(AdminActionTypes.PUT_SPECIAL_CONFIG, data);
export const postImportBulkRequest = (data) =>
  action(AdminActionTypes.POST_IMPORT_BULK, data);
