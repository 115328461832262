import Dispatch from './client';

const urls = {
  userInfo(method, url) {
    return { url: `/users/${url ? 'detials/' + url : 'detials'}`, method };
  },
  postContactInfo: {
    url: '/users/detials/contactInfo',
    method: 'post',
  },
  postVerifyContactInfo: {
    url: '/users/detials/verifyCode',
    method: 'post',
  },
  postSetupSecurity: {
    // url: '/administrator/setUpSecurity',
    url: '/challenge',
    method: 'get',
  },
  getUserInformation: {
    url: '/users/detials',
    method: 'get',
  },
  getUserAuthorization: {
    url: '/users',
    method: 'get',
  },
  getRibbonData: {
    method: 'get',
    url: '/administrator/ribbon',
  },
  postDeleteUserAccountRequest: {
    method: 'post',
    url: '/users/delete-account',
  },
};

function api() {
  return {
    getUserInfo: (data) =>
      Dispatch(urls.userInfo('get'), data.params, data.payload),
    postContactInfo: (data) =>
      Dispatch(urls.postContactInfo, data.params, data.payload),
    postUserSetUpSecurity: (data) =>
      Dispatch(urls.postSetupSecurity, data.params, data.payload),
    postUserInfo: (data) =>
      Dispatch(urls.userInfo('post', data.url), data.params, data.payload),
    postVerifyContactInfo: (data) =>
      Dispatch(urls.postVerifyContactInfo, data.params, data.payload),
    getUserInformation: (data) =>
      Dispatch(urls.getUserInformation, data.params, data.payload),
    getUserAuthorization: (data) =>
      Dispatch(urls.getUserAuthorization, data.params, data.payload),
    getRibbonData: (data) =>
      Dispatch(urls.getRibbonData, data.params, data.payload),
    postDeleteUserAccountRequest: (data) =>
      Dispatch(urls.postDeleteUserAccountRequest, data.params, data.payload),
  };
}

export default api();
