import Dispatch from './client';

const urls = {
  postActivateCard: {
    url: `/auth/pin-config`,
    method: 'post',
  },
};

function api() {
  return {
    postActivateCardRequest: (data) =>
      Dispatch(urls.postActivateCard, {}, data.payload),
  };
}

export default api();
