import { action } from 'typesafe-actions';

export const IndividualActionTypes = {
  GET_ACTIVITY_LOGS: '@@individual/GET_ACTIVITY_LOGS',
  GET_INFORMATION: '@@individual/GET_INFORMATION',
  PUT_REACTIVE_SECURITY: '@@individual/PUT_REACTIVE_SECURITY',
  POST_REDEEM_PROMO_CODE: '@@individual/POST_REDEEM_PROMO_CODE',
  POST_REDEEM_PROMO_CODE_REGISTER:
    '@@individual/POST_REDEEM_PROMO_CODE_REGISTER',
};

export const getActivityLogsRequest = (data) =>
  action(IndividualActionTypes.GET_ACTIVITY_LOGS, data);
export const getInformationRequest = (data) =>
  action(IndividualActionTypes.GET_INFORMATION, data);
export const putReactiveSecurity = (data) =>
  action(IndividualActionTypes.PUT_REACTIVE_SECURITY, data);
export const postRedeemPromoRequest = (data) =>
  action(IndividualActionTypes.POST_REDEEM_PROMO_CODE, data);
export const postRedeemPromoRegisterRequest = (data) =>
  action(IndividualActionTypes.POST_REDEEM_PROMO_CODE_REGISTER, data);
