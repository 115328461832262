import Dispatch from './client';

const apis = {
  getWorkspaceActivityLogs: (workspaceId) => ({
    url: `/administrator/logs/workspace/activity/${workspaceId}`,
    method: 'get',
  }),
};

function api() {
  return {
    getWorkspaceActivityLogs: (data) =>
      Dispatch(
        apis.getWorkspaceActivityLogs(data.params.workspaceId),
        data.params,
        data.payload,
      ),
  };
}

export default api;
