import axios from 'axios';
import tokenService from 'utils/tokenService';
import { errorNotif, resetErrorNotif } from 'utils/notification';
import * as config from 'config';
import i18n from 'i18n';

export const BASE_URL = config.SSO_URL;
export const BASE_URL_AUTHNAPI = config.BASE_URL;
const TokenService = tokenService.getService();
const requestConfig = {
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
  maxRedirects: 0,
  timeout: Number(config.timeout),
};

let isTokenRefreshing = false;
let tokenChannel = new BroadcastChannel('token_channel');
tokenChannel.onmessage = function (event) {
  isTokenRefreshing = event.data;
}; /* receive */

let instance = axios.create(requestConfig);

instance.interceptors.request.use(function (config) {
  const accessToken = TokenService.getAccessToken();
  const azureADToken = TokenService.getAzureADToken();
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }
  if (config.url.includes('/aad/') && azureADToken) {
    config.headers['userId'] = azureADToken;
  }

  if (config.url.includes('/superAdmin')) {
    // Because queries take a long time in Super Admin. The end time for URLs related to Super Admin will be 1 minute
    config.timeout = 60000;
  }
  config.params = Object.assign({}, config.params || {});
  return config;
});

const getDispatch = (url, payload) => {
  return instance.get(url, payload);
};

const postDispatch = (url, payload) => {
  let payLoad;
  payLoad = payload;
  return instance.post(url, payLoad);
};

const putDispatch = (url, payload) => {
  return instance.put(url, payload);
};

const deleteDispatch = (url, payload) => {
  return instance.delete(url, { data: payload });
};

function generateUrl(partialUrl, params = null) {
  let url = BASE_URL + partialUrl;
  let postFix = '';
  if (!params) return url;
  Object.keys(params).map((key) => {
    if (!params[key]) return;
    let searchKey = '{' + key + '}';
    if (url.indexOf(searchKey) !== -1) {
      url = url.replace(searchKey, params[key]);
    } else {
      if (!postFix) {
        postFix = '?';
      } else {
        postFix += '&';
      }
      postFix += key + '=' + params[key];
    }
  });
  return url + postFix;
}

const self = function dispatch(urlInfo, params = null, payload = null) {
  let body;
  const url = generateUrl(urlInfo.url, params);
  if (urlInfo.method === 'get') body = getDispatch;
  if (urlInfo.method === 'post') body = postDispatch;
  if (urlInfo.method === 'put') body = putDispatch;
  if (urlInfo.method === 'delete') body = deleteDispatch;

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return new Promise(function (resolve, reject) {
    body(url, payload)
      .then((data) => {
        resetErrorNotif(url);
        return resolve(data.data);
      })
      .catch(async (err) => {
        resetErrorNotif(url);
        if (err.response) {
          if (err.response.status === 403) {
            TokenService.clearToken();
            // window.location.reload();
          } else if (err.response.status === 401) {
            if (!isTokenRefreshing) {
              tokenChannel.postMessage(true); /* send */
              isTokenRefreshing = true;
              const refreshToken = TokenService.getRefreshToken();
              instance
                .post(
                  `${BASE_URL_AUTHNAPI}/token/refresh-token2?state=${config.LOGIN_CONSTANS.state}`,
                  {
                    refreshToken: refreshToken,
                  },
                )
                .then((res) => {
                  TokenService.setToken(res.data);
                  tokenChannel.postMessage(false);
                  isTokenRefreshing = false;
                  self(urlInfo, params, payload)
                    .then((retryData) => {
                      return resolve(retryData);
                    })
                    .catch((retryError) => {
                      retryError.response && retryError.response.data
                        ? errorNotif(
                            retryError.response.data.message,
                            false,
                            url,
                          )
                        : errorNotif(i18n.t('TIME_OUT'), false, url);
                      reject(retryError);
                    });
                })
                .catch(() => {
                  TokenService.clearToken();
                  tokenChannel.postMessage(false);
                  isTokenRefreshing = false;
                });
            } else {
              // wait for token refresh to complete
              while (isTokenRefreshing) {
                await sleep(100);
              }
              self(urlInfo, params, payload)
                .then((retryData) => {
                  return resolve(retryData);
                })
                .catch((retryError) => {
                  retryError.response && retryError.response.data
                    ? errorNotif(retryError.response.data.message, false, url)
                    : errorNotif(i18n.t('TIME_OUT'), false, url);
                  reject(retryError);
                });
            }
          } else {
            err.response.data
              ? errorNotif(err.response.data.message, false, url)
              : errorNotif(i18n.t('TIME_OUT'), false, url);
            reject(err.response);
          }
        } else {
          errorNotif(i18n.t('TIME_OUT_TRY_AGAIN'), false, url);
          reject(err.response);
        }
      });
  });
};

export default self;
