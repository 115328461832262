// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import tagsApis from 'apis/tagsApis';
// actions
import { TagActionTypes } from 'actions/tagsActions';

export function* handleUploadTagIdsCSV({ payload }) {
  try {
    const response = yield call(tagsApis.uploadTagIdsCSV, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* groupSaga() {
  yield all([
    yield takeEvery(
      TagActionTypes.UPLOAD_TAG_ID_CSV_REQUEST,
      handleUploadTagIdsCSV,
    ),
  ]);
}

export default groupSaga;
