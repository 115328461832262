import Dispatch from './client';

const urls = {
  getStatisticsData: {
    url: '/administrator/statistics',
    method: 'get',
  },
  getStatisticsLogin: {
    url: '/administrator/statistics/login',
    method: 'get',
  },
};

function api() {
  return {
    getStatistics: (data) =>
      Dispatch(urls.getStatisticsData, data.params, data.payload),
    getStatisticsLogin: (data) =>
      Dispatch(urls.getStatisticsLogin, data.params, data.payload),
  };
}

export default api();
