import { v4 } from 'uuid';
import countryList from 'constants/countryList.json';

function diffArray(arr1, arr2) {
  const nameArr1 = arr1.map((item) => item.name);
  const nameArr2 = arr2.map((item) => item.name);
  return arr1.concat(arr2).filter((item) => {
    return !nameArr1.includes(item.name) || !nameArr2.includes(item.name);
  });
}

function generateAddressString(address) {
  const addressArray = [];

  if (!address) return '-';

  if (address.address) addressArray.push(address.address);
  if (address.city) addressArray.push(address.city);
  if (address.state) addressArray.push(address.state);
  if (address.postalCode) {
    if (addressArray[addressArray.length - 1])
      addressArray[addressArray.length - 1] =
        addressArray[addressArray.length - 1] + ` ${address.postalCode}`;
    else addressArray.push(address.postalCode);
  }
  if (address.country) {
    const country = countryList.find((item) => item._id === address.country);
    if (country) {
      addressArray.push(country.name);
    }
  }

  return addressArray.join(', ');
}

function generateFingerprintEnrollmentChallenge() {
  let challenge = v4().split('-').join('');

  return `neshane-${challenge}`;
}

function isEmptyObject(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

function getQueryStringItem(key) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

function checkForDeleteAccountRequest() {
  if (getQueryStringItem('req') === 'account-deletion') {
    localStorage.setItem('deleteAccount', 'true');
  }
}

export {
  diffArray,
  generateAddressString,
  generateFingerprintEnrollmentChallenge,
  isEmptyObject,
  getQueryStringItem,
  checkForDeleteAccountRequest,
};
