import { action } from 'typesafe-actions';

export const dashboardStatisticsActionsTypes = {
  GET_STATISTICS_DATA: '@@dashboardStatistics/GET_STATISTICS_DATA',
  GET_STATISTICS_LOGIN: '@@dashboardStatistics/GET_STATISTICS_LOGIN',
};

export const getStatisticsData = (data) =>
  action(dashboardStatisticsActionsTypes.GET_STATISTICS_DATA, data);
export const getStatisticsLogin = (data) =>
  action(dashboardStatisticsActionsTypes.GET_STATISTICS_LOGIN, data);
