import Dispatch from './client';

const urls = {
  tokenPolicy: (method) => ({
    url: '/administrator/tokenPolicy',
    method: method,
  }),
  defaultTokenPolicy: (method, id) => ({
    url: `/administrator/tokenPolicy/default/${id}`,
    method: method,
  }),
};

function api() {
  return {
    getTokenPolicy: (data) =>
      Dispatch(urls.tokenPolicy('get'), data.params, data.payload),
    postTokenPolicy: (data) =>
      Dispatch(urls.tokenPolicy('post'), data.params, data.payload),
    putDefaultTokenPolicy: (data) =>
      Dispatch(
        urls.defaultTokenPolicy('put', data.params.tokenPolicy),
        {},
        data.payload,
      ),
  };
}

export default api();
