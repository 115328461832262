import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOneBillCSVRequest } from 'actions/billingActions';
import { Spin } from 'antd';
import {
  getCurrentInvoiceRequest,
  getWorkspaceInvoiceRequest,
} from 'actions/adminActions';

const BillingPDF = ({
  getOneBillCSVRequest,
  getCurrentInvoiceRequest,
  getWorkspaceInvoiceRequest,
}) => {
  const [htmlContent, setHtmlContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');
  const plan = urlParams.get('paymentMethod');
  const workspaceId = urlParams.get('workspaceId');

  const getBillData = () => {
    if (status === 'current') {
      getCurrentInvoiceRequest({
        workspaceId,
        params: { paymentMethod: plan },
        onSuccessCallback: (response) => {
          setHtmlContent(response);
          setIsLoading(false);
        },
        onErrorCallback: () => {
          setIsLoading(false);
        },
      });
    } else if (status === 'custom' && workspaceId) {
      getWorkspaceInvoiceRequest({
        workspaceId,
        invoiceId: id,
        onSuccessCallback: (response) => {
          setIsLoading(false);
          setHtmlContent(response);
        },
        onErrorCallback: () => {
          setIsLoading(false);
        },
      });
    } else {
      getOneBillCSVRequest({
        values: { _id: id },
        onSuccessCallback: async (response) => {
          setHtmlContent(response);
          setIsLoading(false);
        },
        onErrorCallback: () => {
          setIsLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    getBillData();
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
      </Spin>
    </>
  );
};

BillingPDF.propTypes = {
  getOneBillCSVRequest: PropTypes.func.isRequired,
  getCurrentInvoiceRequest: PropTypes.func.isRequired,
  getWorkspaceInvoiceRequest: PropTypes.func.isRequired,
};

const billingPdfConnect = connect(null, {
  getOneBillCSVRequest,
  getCurrentInvoiceRequest,
  getWorkspaceInvoiceRequest,
})(BillingPDF);

export default billingPdfConnect;
