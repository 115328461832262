import { action } from 'typesafe-actions';

export const UsersActionTypes = {
  GET_USERS_REQUEST: '@@users/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: '@@users/GET_USERS_SUCCESS',
  GET_USERS_FAIL: '@@users/GET_USERS_FAIL',

  POST_NEW_USERS_REQUESR: '@@users/POST_NEW_USERS_REQUEST',
  POST_RESEND_EMAIL_REQUEST: '@@users/POST_RESEND_EMAIL_REQUEST',
  DELETE_USER_REQUEST: '@@users/DELETE_USER_REQUEST',
  PUT_ACTIVE_USER_REQUEST: '@@users/PUT_ACTIVE_USER_REQUEST',
  PUT_DEACTIVE_USER_REQUEST: '@@users/PUT_DEACTIVE_USER_REQUEST',

  POST_UPLOAD_CSVFILE_REQUEST: '@@users/POST_UPLOAD_CSVFILE_REQUEST',
  DELETE_UPLOAD_CSVFILE_REQUEST: '@@users/DELETE_UPLOAD_CSVFILE_REQUEST',

  POST_DELETE_Many_USERS_REQUEST: '@@users/POST_DELETE_Many_USERS_REQUEST',
  POST_DEACTIVE_MANY_USERS_REQUEST: '@@users/POST_DEACTIVE_MANY_USERS_REQUEST',
  POST_ACTIVE_MANY_USERS_REQUEST: '@@users/POST_ACTIVE_MANY_USERS_REQUEST',
  POST_RESEND_EMAIL_MANY_USERS_REQUEST:
    '@@users/POST_RESEND_EMAIL_MANY_USERS_REQUEST',

  PUT_CHANGE_TOKEN_POLICY_USER_REQUEST:
    '@@user/PUT_CHANGE_TOKEN_POLICY_USER_REQUEST',

  POST_ADD_TO_THIS_DOMAIN_REQUEST: '@@user/POST_ADD_TO_THIS_DOMAIN_REQUEST',
  POST_SET_DEFAULT_OBR_REQUEST: '@@user/POST_SET_DEFAULT_OBR_REQUEST',

  POST_ASSIGN_SECURITY_KEY: '@@user/POST_ASSIGN_SECURITY_KEY',
  GET_TEMPORARY_ACCESS: '@@user/GET_TEMPORARY_ACCESS',
  PUT_CHANGE_USER_INFO: '@@user/PUT_CHANGE_USER_INFO',
  GET_USER_INFO: '@@user/GET_USER_INFO',

  GET_GROUPS_IN_ADD_USER: '@@user/GET_GROUPS_IN_ADD_USER',
  POST_RESET_PIN: '@@user/POST_RESET_PIN',

  DELETE_ALL_SECURITY_KEYS: '@@user/DELETE_ALL_SECURITY_KEYS',

  PUT_SUSPEND_MANY_USERS_REQUEST: '@@users/PUT_SUSPEND_MANY_USERS_REQUEST',
  DELETE_SECURITY_DEVICE_REQUEST: '@@users/DELETE_SECURITY_DEVICE_REQUEST',
  PUT_SECURITY_DEVICE_NAME_REQUEST: '@@users/PUT_SECURITY_DEVICE_NAME_REQUEST',

  GET_MS_TAP: '@@users/GET_MS_TAP',
  PUT_ENABLE_MS_TAP: '@@users/PUT_ENABLE_MS_TAP',
  POST_AUTO_OBR: '@@users/POST_AUTO_OBR',
  GET_AUTO_OBR_RESULTS: '@@users/GET_AUTO_OBR_RESULTS',

  GET_USER_SECURITY_KEYS: '@@users/GET_USER_SECURITY_KEYS',
  POST_ADD_USER_GROUPS: '@@users/POST_ADD_USER_GROUPS',
  DELETE_USER_GROUPS: '@@users/DELETE_USER_GROUPS',
  POST_ADD_USER_SECURITY_KEY: '@@users/POST_ADD_USER_SECURITY_KEY',
  GET_USER_DEVICES: '@@users/GET_USER_DEVICES',
  GET_USER_SHARED_DEVICES: '@@users/GET_USER_SHARED_DEVICES',
  GET_ALL_USER_PASSWORD: '@@users/GET_ALL_USER_PASSWORD',
  ADD_USER_PASSWORD: '@@users/ADD_USER_PASSWORD',
  EDIT_USER_PASSWORD: '@@users/EDIT_USER_PASSWORD',
  DELETE_USER_PASSWORD: '@@users/DELETE_USER_PASSWORD',
  PUT_LAST_SEEN_CHANGE_LOG: '@@users/PUT_LAST_SEEN_CHANGE_LOG',
  GET_USER_PASSKEYS: '@@users/GET_USER_PASSKEYS',
  GET_USER_AUTH_ATTEMPTS: '@@users/GET_USER_AUTH_ATTEMPTS',
};

export const getUsersRequest = (data) =>
  action(UsersActionTypes.GET_USERS_REQUEST, data);
export const getUsersSuccess = (data) =>
  action(UsersActionTypes.GET_USERS_SUCCESS, data);
export const getUsersFail = (data) =>
  action(UsersActionTypes.GET_USERS_FAIL, data);
export const postNewUsersRequest = (data) =>
  action(UsersActionTypes.POST_NEW_USERS_REQUESR, data);
export const postResendEmailRequest = (data) =>
  action(UsersActionTypes.POST_RESEND_EMAIL_REQUEST, data);
export const deleteUserRequest = (data) =>
  action(UsersActionTypes.DELETE_USER_REQUEST, data);
export const putActiveUserRequest = (data) =>
  action(UsersActionTypes.PUT_ACTIVE_USER_REQUEST, data);
export const putDeActiveUserRequest = (data) =>
  action(UsersActionTypes.PUT_DEACTIVE_USER_REQUEST, data);
export const postUploadCSVFile = (data) =>
  action(UsersActionTypes.POST_UPLOAD_CSVFILE_REQUEST, data);
export const deleteUploadCSVFile = (data) =>
  action(UsersActionTypes.DELETE_UPLOAD_CSVFILE_REQUEST, data);
export const postDeleteManyUsersRequest = (data) =>
  action(UsersActionTypes.POST_DELETE_Many_USERS_REQUEST, data);
export const postDeactiveManyUsersRequest = (data) =>
  action(UsersActionTypes.POST_DEACTIVE_MANY_USERS_REQUEST, data);
export const postActiveManyUsersRequest = (data) =>
  action(UsersActionTypes.POST_ACTIVE_MANY_USERS_REQUEST, data);
export const postResendEmailManyUsersRequest = (data) =>
  action(UsersActionTypes.POST_RESEND_EMAIL_MANY_USERS_REQUEST, data);
export const putChangeTokenPolicy = (data) =>
  action(UsersActionTypes.PUT_CHANGE_TOKEN_POLICY_USER_REQUEST, data);
export const postAddToThisDomain = (data) =>
  action(UsersActionTypes.POST_ADD_TO_THIS_DOMAIN_REQUEST, data);
export const postSetDefaultOBR = (data) =>
  action(UsersActionTypes.POST_SET_DEFAULT_OBR_REQUEST, data);
export const postAssignSecurityKey = (data) =>
  action(UsersActionTypes.POST_ASSIGN_SECURITY_KEY, data);
export const getTemporaryAccess = (data) =>
  action(UsersActionTypes.GET_TEMPORARY_ACCESS, data);
export const getGroupsInAddUser = (data) =>
  action(UsersActionTypes.GET_GROUPS_IN_ADD_USER, data);
export const putChangeUserInfo = (data) =>
  action(UsersActionTypes.PUT_CHANGE_USER_INFO, data);
export const getUserInfo = (data) =>
  action(UsersActionTypes.GET_USER_INFO, data);
export const putResetPin = (data) =>
  action(UsersActionTypes.POST_RESET_PIN, data);
export const deleteAllSecurityKeys = (data) =>
  action(UsersActionTypes.DELETE_ALL_SECURITY_KEYS, data);
export const putSuspendManyUsersRequest = (data) =>
  action(UsersActionTypes.PUT_SUSPEND_MANY_USERS_REQUEST, data);
export const getMsTAPRequest = (data) =>
  action(UsersActionTypes.GET_MS_TAP, data);
export const putEnableMsTAPRequest = (data) =>
  action(UsersActionTypes.PUT_ENABLE_MS_TAP, data);
export const postAutoOBR = (data) =>
  action(UsersActionTypes.POST_AUTO_OBR, data);
export const getAutoOBRResults = (data) =>
  action(UsersActionTypes.GET_AUTO_OBR_RESULTS, data);
export const putSecurityDeviceName = (data) =>
  action(UsersActionTypes.PUT_SECURITY_DEVICE_NAME_REQUEST, data);
export const getUserSecuritykeys = (data) =>
  action(UsersActionTypes.GET_USER_SECURITY_KEYS, data);
export const postAddUserGroups = (data) =>
  action(UsersActionTypes.POST_ADD_USER_GROUPS, data);
export const deleteUserGroups = (data) =>
  action(UsersActionTypes.DELETE_USER_GROUPS, data);
export const postAddUserSecuritykey = (data) =>
  action(UsersActionTypes.POST_ADD_USER_SECURITY_KEY, data);
export const getUserDevices = (data) =>
  action(UsersActionTypes.GET_USER_DEVICES, data);
export const getUserSharedDevices = (data) =>
  action(UsersActionTypes.GET_USER_SHARED_DEVICES, data);
export const getAllUserPassword = (data) =>
  action(UsersActionTypes.GET_ALL_USER_PASSWORD, data);
export const addUserPassword = (data) =>
  action(UsersActionTypes.ADD_USER_PASSWORD, data);
export const editUserPassword = (data) =>
  action(UsersActionTypes.EDIT_USER_PASSWORD, data);
export const deleteUserPassword = (data) =>
  action(UsersActionTypes.DELETE_USER_PASSWORD, data);
export const putLastSeenChangeLog = (data) =>
  action(UsersActionTypes.PUT_LAST_SEEN_CHANGE_LOG, data);
export const getUserPasskeys = (data) =>
  action(UsersActionTypes.GET_USER_PASSKEYS, data);
export const getUserLoginAttempts = (data) =>
  action(UsersActionTypes.GET_USER_AUTH_ATTEMPTS, data);
