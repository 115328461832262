export const PUBLIC_URL =
  process.env.REACT_APP_PUBLIC_URL || '${REACT_APP_PUBLIC_URL}';
export const NODE_ENV = process.env.NODE_ENV;
export const BASE_URL =
  process.env.REACT_APP_SERVER_PUBLIC_URL || '${REACT_APP_SERVER_PUBLIC_URL}';
export const SITEKEY_HCAPTCHA =
  process.env.REACT_APP_HCAPTCHA_SITE_KEY || '${REACT_APP_HCAPTCHA_SITE_KEY}';
export const SITEKEY_ARCAPTCHA =
  process.env.REACT_APP_ARCAPTCHA_SITE_KEY || '${REACT_APP_ARCAPTCHA_SITE_KEY}';
export const CAPTCHA_PROVIDER =
  process.env.REACT_APP_CAPTCHA_PROVIDER || '${REACT_APP_CAPTCHA_PROVIDER}';
export const timeout =
  process.env.REACT_APP_PANEL_TIMEOUT || '${REACT_APP_PANEL_TIMEOUT}';
export const NAME = process.env.REACT_APP_APP_NAME || '${REACT_APP_APP_NAME}';
export const RP_ICON =
  process.env.REACT_APP_AUTO_ENROLLMENT_RP_ICON ||
  '${REACT_APP_AUTO_ENROLLMENT_RP_ICON}';
export const EULA_PAGE = process.env.REACT_APP_COMPANY_URL
  ? `${process.env.REACT_APP_COMPANY_URL}/end-user-license-agreement-admins`
  : '${REACT_APP_COMPANY_URL}/end-user-license-agreement-admins';
export const PRICAVY_POLICY_PAGE = process.env.REACT_APP_COMPANY_URL
  ? `${process.env.REACT_APP_COMPANY_URL}/privacy-policy/`
  : '${REACT_APP_COMPANY_URL}/privacy-policy/';
export const COMPANY_SITE =
  process.env.REACT_APP_COMPANY_URL || '${REACT_APP_COMPANY_URL}';
export const PAYPAL = {
  CLIENT_ID: '',
};
export const serverName = 'neshane';
export const LOGIN_CONSTANS = {
  loginURL: process.env.REACT_APP_SSO_URL
    ? `${process.env.REACT_APP_SSO_URL}/oidc/idp/authorize`
    : '${REACT_APP_SSO_URL}/oidc/idp/authorize',
  response_type:
    process.env.REACT_APP_SSO_LOGIN_RESPONSE_TYPE ||
    '${REACT_APP_SSO_LOGIN_RESPONSE_TYPE}',
  client_id:
    process.env.REACT_APP_SSO_LOGIN_CLIENT_ID ||
    '${REACT_APP_SSO_LOGIN_CLIENT_ID}',
  nonce:
    process.env.REACT_APP_SSO_LOGIN_NONCE || '${REACT_APP_SSO_LOGIN_NONCE}',
  state:
    process.env.REACT_APP_SSO_LOGIN_STATE || '${REACT_APP_SSO_LOGIN_STATE}',
  scope:
    process.env.REACT_APP_SSO_LOGIN_SCOPE || '${REACT_APP_SSO_LOGIN_SCOPE}',
  redirect_url: process.env.REACT_APP_SSO_REDIRECT_URL
    ? `${process.env.REACT_APP_SSO_REDIRECT_URL}/login/sso`
    : '${REACT_APP_SSO_REDIRECT_URL}/login/sso',
  logoutURL: process.env.REACT_APP_SSO_URL
    ? `${process.env.REACT_APP_SSO_URL}/oidc/idp/logout`
    : '${REACT_APP_SSO_URL}/oidc/idp/logout',
  redirect_url_logout: process.env.REACT_APP_PANEL_URL
    ? `${process.env.REACT_APP_PANEL_URL}/auth/logout`
    : '${REACT_APP_PANEL_URL}/auth/logout',
};
export const RP_ID =
  process.env.REACT_APP_AUTO_ENROLLMENT_RP_ID ||
  '${REACT_APP_AUTO_ENROLLMENT_RP_ID}';
export const SSO_URL = process.env.REACT_APP_SSO_URL || '${REACT_APP_SSO_URL}';
export const DEMO_URL = 'demo.neshane.co';
export const MFA = process.env.REACT_APP_MFA_ENABLE || '${REACT_APP_MFA_ENABLE}';