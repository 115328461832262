// node modules
import { all, call, takeEvery, put } from 'redux-saga/effects';
// apis
import usersApi from 'apis/userApis';
// actions
import {
  UsersActionTypes,
  getUsersSuccess,
  getUsersFail,
} from 'actions/userActions';

export function* handleGetUsers({ payload }) {
  try {
    const response = yield call(usersApi.getAllUsers, payload);
    yield put(getUsersSuccess(response));
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    yield put(getUsersFail(err));
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostNewUsers({ payload }) {
  try {
    const response = yield call(usersApi.postNewUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePostResendEmail({ payload }) {
  try {
    const response = yield call(usersApi.postResendEmail, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleDeleteUser({ payload }) {
  try {
    const response = yield call(usersApi.deleteUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutActiveUser({ payload }) {
  try {
    const response = yield call(usersApi.putActiveUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutDeActiveUser({ payload }) {
  try {
    const response = yield call(usersApi.putDeActiveUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostUploadCsvFile({ payload }) {
  try {
    const response = yield call(usersApi.postUploadCsvFile, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteUploadCsvFile({ payload }) {
  try {
    const response = yield call(usersApi.deleteUploadCsvFile, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostDeleteManyUsers({ payload }) {
  try {
    const response = yield call(usersApi.postDeleteManyUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostDeactiveManyUsers({ payload }) {
  try {
    const response = yield call(usersApi.postDeactiveManyUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostActiveManyUsers({ payload }) {
  try {
    const response = yield call(usersApi.postActiveManyUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostResendEmailManyUsers({ payload }) {
  try {
    const response = yield call(usersApi.postResendEmailManyUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutChangeTokenPolicy({ payload }) {
  try {
    const response = yield call(usersApi.putChangeTokenPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAddToThisDomain({ payload }) {
  try {
    const response = yield call(usersApi.postAddToThisDomain, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostSetDefaultOBR({ payload }) {
  try {
    const response = yield call(usersApi.postSetDefaultOBR, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAssignSecurityKey({ payload }) {
  try {
    const response = yield call(usersApi.postAssignSecurityKey, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
export function* handleGetTemporaryAccess({ payload }) {
  try {
    const response = yield call(usersApi.getTemporaryAccess, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetGroupsInAddUser({ payload }) {
  try {
    const response = yield call(usersApi.getGroupsInAddUser, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutChangeUserInfo({ payload }) {
  try {
    const response = yield call(usersApi.putChangeUserInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}
export function* handleGetUserInfo({ payload }) {
  try {
    const response = yield call(usersApi.getUserInfo, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutResetPin({ payload }) {
  try {
    const response = yield call(usersApi.putResetPin, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteAllSecurityKeys({ payload }) {
  try {
    const response = yield call(usersApi.deleteAllSecurityKeys, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutSuspendManyUsers({ payload }) {
  try {
    const response = yield call(usersApi.putSuspendManyUsers, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetMsTAP({ payload }) {
  try {
    const response = yield call(usersApi.getMsTAP, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePutEnableMsTAP({ payload }) {
  try {
    const response = yield call(usersApi.putEnableMsTAP, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback(err);
  }
}

export function* handlePostAutoOBR({ payload }) {
  try {
    const response = yield call(usersApi.postAutoOBR, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetAutoOBRResults({ payload }) {
  try {
    const response = yield call(usersApi.getAutoOBRResults, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutSecurityDeviceName({ payload }) {
  try {
    const response = yield call(usersApi.putSecurityDeviceName, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserSecuritykeys({ payload }) {
  try {
    const response = yield call(usersApi.getUserSecuritykeys, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAddUserSecuritykeys({ payload }) {
  try {
    const response = yield call(usersApi.postAddUserSecuritykeys, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePostAddUserGroups({ payload }) {
  try {
    const response = yield call(usersApi.postAddUserGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteUserGroups({ payload }) {
  try {
    const response = yield call(usersApi.deleteUserGroups, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserDevice({ payload }) {
  try {
    const response = yield call(usersApi.getUserDevice, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserSharedDevices({ payload }) {
  try {
    const response = yield call(usersApi.getUserSharedDevices, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetAllUserPassword({ payload }) {
  try {
    const response = yield call(usersApi.getAllUserPassword, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleAddUserPassword({ payload }) {
  try {
    const response = yield call(usersApi.addUserPassword, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleEditUserPassword({ payload }) {
  try {
    const response = yield call(usersApi.editUserPassword, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteUserPassword({ payload }) {
  try {
    const response = yield call(usersApi.deleteUserPassword, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutLastSeenChangeLog({ payload }) {
  try {
    const response = yield call(usersApi.putLastSeenChangeLog, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetLoginAttempts({ payload} ){
  try {
    const response = yield call(usersApi.getUserLoginAttempts, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetUserPasskey({ payload }) {
  try {
    const response = yield call(usersApi.getUserPasskey, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* UsersSaga() {
  yield all([
    yield takeEvery(UsersActionTypes.GET_USERS_REQUEST, handleGetUsers),
    yield takeEvery(
      UsersActionTypes.POST_NEW_USERS_REQUESR,
      handlePostNewUsers,
    ),
    yield takeEvery(
      UsersActionTypes.POST_RESEND_EMAIL_REQUEST,
      handlePostResendEmail,
    ),
    yield takeEvery(UsersActionTypes.DELETE_USER_REQUEST, handleDeleteUser),
    yield takeEvery(
      UsersActionTypes.PUT_ACTIVE_USER_REQUEST,
      handlePutActiveUser,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_DEACTIVE_USER_REQUEST,
      handlePutDeActiveUser,
    ),
    yield takeEvery(
      UsersActionTypes.POST_UPLOAD_CSVFILE_REQUEST,
      handlePostUploadCsvFile,
    ),
    yield takeEvery(
      UsersActionTypes.DELETE_UPLOAD_CSVFILE_REQUEST,
      handleDeleteUploadCsvFile,
    ),
    yield takeEvery(
      UsersActionTypes.POST_DELETE_Many_USERS_REQUEST,
      handlePostDeleteManyUsers,
    ),
    yield takeEvery(
      UsersActionTypes.POST_DEACTIVE_MANY_USERS_REQUEST,
      handlePostDeactiveManyUsers,
    ),
    yield takeEvery(
      UsersActionTypes.POST_ACTIVE_MANY_USERS_REQUEST,
      handlePostActiveManyUsers,
    ),
    yield takeEvery(
      UsersActionTypes.POST_RESEND_EMAIL_MANY_USERS_REQUEST,
      handlePostResendEmailManyUsers,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_CHANGE_TOKEN_POLICY_USER_REQUEST,
      handlePutChangeTokenPolicy,
    ),
    yield takeEvery(
      UsersActionTypes.POST_ADD_TO_THIS_DOMAIN_REQUEST,
      handlePostAddToThisDomain,
    ),
    yield takeEvery(
      UsersActionTypes.POST_SET_DEFAULT_OBR_REQUEST,
      handlePostSetDefaultOBR,
    ),
    yield takeEvery(
      UsersActionTypes.POST_ASSIGN_SECURITY_KEY,
      handlePostAssignSecurityKey,
    ),
    yield takeEvery(
      UsersActionTypes.GET_TEMPORARY_ACCESS,
      handleGetTemporaryAccess,
    ),
    yield takeEvery(
      UsersActionTypes.GET_GROUPS_IN_ADD_USER,
      handleGetGroupsInAddUser,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_CHANGE_USER_INFO,
      handlePutChangeUserInfo,
    ),
    yield takeEvery(UsersActionTypes.GET_USER_INFO, handleGetUserInfo),
    yield takeEvery(UsersActionTypes.POST_RESET_PIN, handlePutResetPin),
    yield takeEvery(
      UsersActionTypes.DELETE_ALL_SECURITY_KEYS,
      handleDeleteAllSecurityKeys,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_SUSPEND_MANY_USERS_REQUEST,
      handlePutSuspendManyUsers,
    ),
    yield takeEvery(UsersActionTypes.GET_MS_TAP, handleGetMsTAP),
    yield takeEvery(UsersActionTypes.PUT_ENABLE_MS_TAP, handlePutEnableMsTAP),
    yield takeEvery(UsersActionTypes.POST_AUTO_OBR, handlePostAutoOBR),
    yield takeEvery(
      UsersActionTypes.GET_AUTO_OBR_RESULTS,
      handleGetAutoOBRResults,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_SECURITY_DEVICE_NAME_REQUEST,
      handlePutSecurityDeviceName,
    ),

    yield takeEvery(
      UsersActionTypes.GET_USER_SECURITY_KEYS,
      handleGetUserSecuritykeys,
    ),
    yield takeEvery(
      UsersActionTypes.POST_ADD_USER_SECURITY_KEY,
      handlePostAddUserSecuritykeys,
    ),
    yield takeEvery(
      UsersActionTypes.POST_ADD_USER_GROUPS,
      handlePostAddUserGroups,
    ),
    yield takeEvery(
      UsersActionTypes.DELETE_USER_GROUPS,
      handleDeleteUserGroups,
    ),
    yield takeEvery(UsersActionTypes.GET_USER_DEVICES, handleGetUserDevice),
    yield takeEvery(
      UsersActionTypes.GET_USER_SHARED_DEVICES,
      handleGetUserSharedDevices,
    ),
    yield takeEvery(
      UsersActionTypes.GET_ALL_USER_PASSWORD,
      handleGetAllUserPassword,
    ),
    yield takeEvery(
      UsersActionTypes.EDIT_USER_PASSWORD,
      handleEditUserPassword,
    ),
    yield takeEvery(UsersActionTypes.ADD_USER_PASSWORD, handleAddUserPassword),
    yield takeEvery(
      UsersActionTypes.DELETE_USER_PASSWORD,
      handleDeleteUserPassword,
    ),
    yield takeEvery(
      UsersActionTypes.PUT_LAST_SEEN_CHANGE_LOG,
      handlePutLastSeenChangeLog,
    ),
    yield takeEvery(UsersActionTypes.GET_USER_PASSKEYS, handleGetUserPasskey),
    yield takeEvery(UsersActionTypes.GET_USER_AUTH_ATTEMPTS, handleGetLoginAttempts),
  ]);
}

export default UsersSaga;
