import * as CONFIG from 'config';
import { clearStorageKeepUsers } from './storageUtils';
import { checkForDeleteAccountRequest } from 'utils';
import AuthenApis from '../apis/authenticationApis';

const LOGIN_CONSTANS = CONFIG.LOGIN_CONSTANS;

const tokenService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  async function _setAzureADToken(azureAD) {
    localStorage.setItem('azureAD', azureAD);
  }

  async function _setToken(tokenObj) {
    localStorage.setItem('accessToken', tokenObj.accessToken);
    localStorage.setItem('refreshToken', tokenObj.refreshToken);
    localStorage.setItem('id_token', tokenObj.id_token);
  }

  function _setAccessToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }

  function _getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function _getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
  function _getAzureADToken() {
    return localStorage.getItem('azureAD');
  }
  function _cleareAzureADToken() {
    return localStorage.removeItem('azureAD');
  }
  function _getid_token() {
    return localStorage.getItem('id_token');
  }
  function _clearToken(goTologoutPage, urlAfterLogount) {
    checkForDeleteAccountRequest();
    const logoutRedirect = goTologoutPage
      ? `${window.location.origin}/auth/logout`
      : urlAfterLogount
        ? urlAfterLogount
        : LOGIN_CONSTANS.redirect_url;
    const refresh = localStorage.getItem('refreshToken');
    if (refresh) {
      localStorage.removeItem('refreshToken')
      AuthenApis.logout().finally(() => {
        window.location.replace(
          `${
            LOGIN_CONSTANS.logoutURL
          }/?post_logout_redirect_uri=${logoutRedirect}&id_token_hint=${_getid_token()}&state=logout`,
        );

        clearStorageKeepUsers();
      });
    } else {
      window.location.replace(
        `${
          LOGIN_CONSTANS.logoutURL
        }/?post_logout_redirect_uri=${logoutRedirect}&id_token_hint=${_getid_token()}&state=logout`,
      );

      clearStorageKeepUsers();
    }
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setAccessToken: _setAccessToken,
    setAzureADToken: _setAzureADToken,
    getAzureADToken: _getAzureADToken,
    cleareAzureADToken: _cleareAzureADToken,
  };
})();
export default tokenService;
