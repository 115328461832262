import Dispatch from './client';

const urls = {
  uploadTagIdsCSV: {
    method: 'post',
    url: '/administrator/tag/uploadCsv',
  },
};

function api() {
  return {
    uploadTagIdsCSV: (data) =>
      Dispatch(urls.uploadTagIdsCSV, data.params, data.payload),
  };
}

export default api();
