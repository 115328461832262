import Dispatch from './client';

const urls = {
  accessPolicy: (method) => ({
    url: '/administrator/accessPolicy',
    method: method,
  }),
  updateAccessPolicy: (method, id) => ({
    url: `/administrator/accessPolicy/${id}`,
    method: method,
  }),
  getAllPolicy: {
    method: 'get',
    url: '/administrator/policy',
  },
};

function api() {
  return {
    getAccessPolicy: (data) =>
      Dispatch(urls.accessPolicy('get'), data.params, data.payload),
    postAccessPolicy: (data) =>
      Dispatch(urls.accessPolicy('post'), data.params, data.payload),
    putAccessPolicy: (data) =>
      Dispatch(
        urls.updateAccessPolicy('put', data.params.accessPolicy),
        {},
        data.payload,
      ),
    deleteAccessPolicy: (data) =>
      Dispatch(
        urls.updateAccessPolicy('delete', data.params.accessPolicy),
        {},
        data.payload,
      ),
    getAllPolicy: (data) =>
      Dispatch(urls.getAllPolicy, data.params, data.payload),
  };
}

export default api();
