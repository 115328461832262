import { action } from 'typesafe-actions';

export const OUsActionTypes = {
  GET_USERS: '@@organizationUnits/GET_USERS',
  GET_ORGANIZATION_UNITS: '@@organizationUnits/GET_ORGANIZATION_UNITS',
  GET_ORGANIZATION_UNIT: '@@organizationUnits/GET_ORGANIZATION_UNIT',
  GET_ORGANIZATION_UNITS_NAMES:
    '@@organizationUnits/GET_ORGANIZATION_UNITS_NAMES',
  CREATE_ORGANIZATION_UNIT: '@@organizationUnits/CREATE_ORGANIZATION_UNIT',
  DELETE_ORGANIZATION_UNITS: '@@organizationUnits/DELETE_ORGANIZATION_UNITS',
  UPDATE_ORGANIZATION_UNITS: '@@organizationUnits/UPDATE_ORGANIZATION_UNITS',

  GET_USER_ORGANIZATION_UNITS:
    '@@organizationUnits/GET_USER_ORGANIZATION_UNITS',
  DELETE_USER_FROM_ORGANIZATION_UNITS:
    '@@organizationUnits/DELETE_USER_FROM_ORGANIZATION_UNITS',
  ADD_USER_TO_ORGANIZATION_UNITS:
    '@@organizationUnits/ADD_USER_TO_ORGANIZATION_UNITS',
};

export const getUserRequest = (data) => action(OUsActionTypes.GET_USERS, data);
export const getOrganizationUnitsRequest = (data) =>
  action(OUsActionTypes.GET_ORGANIZATION_UNITS, data);
export const getOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.GET_ORGANIZATION_UNIT, data);
export const getOrganizationUnitsNamesRequest = (data) =>
  action(OUsActionTypes.GET_ORGANIZATION_UNITS_NAMES, data);
export const createOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.CREATE_ORGANIZATION_UNIT, data);
export const deleteOrganizationUnitsRequest = (data) =>
  action(OUsActionTypes.DELETE_ORGANIZATION_UNITS, data);
export const updateOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.UPDATE_ORGANIZATION_UNITS, data);

export const getUserOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.GET_USER_ORGANIZATION_UNITS, data);

export const deleteUserFromOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.DELETE_USER_FROM_ORGANIZATION_UNITS, data);

export const AddUserToOrganizationUnitRequest = (data) =>
  action(OUsActionTypes.ADD_USER_TO_ORGANIZATION_UNITS, data);
