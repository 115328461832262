import { action } from 'typesafe-actions';

export const BillingActionTypes = {
  GET_BILLING_STATUS: '@@billing/GET_BILLING_STATUS',
  GET_BILLING_HISTORY: '@@billing/GET_BILLING_HISTORY',
  GET_BILLING_POLICY: '@@billing/GET_BILLING_POLICY',
  GET_ONE_BILL_CSV: '@@billing/GET_ONE_BILL_CSV',
  POST_ADD_CREDIT_CARD: '@@billing/POST_ADD_CREDIT_CARD',
  GET_ALL_CREDIT_CARDS: '@@billing/GET_ALL_CREDIT_CARDS',
  DELETE_CREDIT_CARD: '@@billing/DELETE_CREDIT_CARD',
  EDIT_CREDIT_CARD: '@@billing/EDIT_CREDIT_CARD',
  EDIT_BILLING_ADDRESS: '@@billing/EDIT_BILLING_ADDRESS',
  PUT_CHECKOUT_OVERDUE_INVOICE: '@@billing/PUT_CHECKOUT_OVERDUE_INVOICE',
  POST_ANNUAL_SUBSCRIPTION_REQUEST:
    '@@billing/POST_ANNUAL_SUBSCRIPTION_REQUEST',
};

export const getBillingStatusRequest = (data) =>
  action(BillingActionTypes.GET_BILLING_STATUS, data);
export const getBillingHistoryRequest = (data) =>
  action(BillingActionTypes.GET_BILLING_HISTORY, data);
export const getBillingPolicyRequest = (data) =>
  action(BillingActionTypes.GET_BILLING_POLICY, data);
export const getOneBillCSVRequest = (data) =>
  action(BillingActionTypes.GET_ONE_BILL_CSV, data);
export const postAddCreditCardRequest = (data) =>
  action(BillingActionTypes.POST_ADD_CREDIT_CARD, data);
export const getAllCreditCardsRequest = (data) =>
  action(BillingActionTypes.GET_ALL_CREDIT_CARDS, data);
export const deleteCreditCardRequest = (data) =>
  action(BillingActionTypes.DELETE_CREDIT_CARD, data);
export const editCreditCardRequest = (data) =>
  action(BillingActionTypes.EDIT_CREDIT_CARD, data);
export const editBillingAddressRequest = (data) =>
  action(BillingActionTypes.EDIT_BILLING_ADDRESS, data);
export const putCheckoutOverdueInvoiceRequest = (data) =>
  action(BillingActionTypes.PUT_CHECKOUT_OVERDUE_INVOICE, data);
export const postAnnualSubscriptionRequest = (data) =>
  action(BillingActionTypes.POST_ANNUAL_SUBSCRIPTION_REQUEST, data);
