import { action } from 'typesafe-actions';

export const paymentConfirmActionTypes = {
  GET_CARDS: '@@paymentConfirm/GET_CARDS',
  POST_CARDS: '@@paymentConfirm/POST_CARDS',
  DELETE_CARDS: '@@paymentConfirm/DELETE_CARDS',
  ADD_CONFIRM: '@@paymentConfirm/ADD_CONFIRM',
  GET_CONFIRM: '@@paymentConfirm/GET_CONFIRM',
};

export const getCardsRequest = (data) =>
  action(paymentConfirmActionTypes.GET_CARDS, data);
export const addCardsRequest = (data) =>
  action(paymentConfirmActionTypes.POST_CARDS, data);
export const deleteCardsRequest = (data) =>
  action(paymentConfirmActionTypes.DELETE_CARDS, data);
export const addConfirmRequest = (data) =>
  action(paymentConfirmActionTypes.ADD_CONFIRM, data);
export const getConfirmRequest = (data) =>
  action(paymentConfirmActionTypes.GET_CONFIRM, data);
