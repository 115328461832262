// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import logsApis from 'apis/logApis';
// actions
import { LogsActionTypes } from 'actions/logsActions';

export function* handleGetWorkspaceActivityLogs({ payload }) {
  try {
    const response = yield call(logsApis().getWorkspaceActivityLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* logsSaga() {
  yield all([
    yield takeEvery(
      LogsActionTypes.GET_WORKSPACE_ACTIVITY_LOGS_REQUEST,
      handleGetWorkspaceActivityLogs,
    ),
  ]);
}

export default logsSaga;
