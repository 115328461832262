import { all, call, takeEvery } from 'redux-saga/effects';
import statisticsApis from 'apis/dashboardStatisticsApis';
import { dashboardStatisticsActionsTypes } from 'actions/dashboardStatisticsActions';

export function* handleGetStatistics({ payload }) {
  try {
    const response = yield call(statisticsApis.getStatistics, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetStatisticsLogin({ payload }) {
  try {
    const response = yield call(statisticsApis.getStatisticsLogin, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
function* statisticsSaga() {
  yield all([
    yield takeEvery(
      dashboardStatisticsActionsTypes.GET_STATISTICS_DATA,
      handleGetStatistics,
    ),
  ]);
  yield all([
    yield takeEvery(
      dashboardStatisticsActionsTypes.GET_STATISTICS_LOGIN,
      handleGetStatisticsLogin,
    ),
  ]);
}

export default statisticsSaga;
