import { action } from 'typesafe-actions';

export const CustomersActionTypes = {
  GET_CUSTOMER_INFO_REQUEST: '@@customers/GET_CUSTOMER_INFO_REQUEST',
  GET_CUSTOMER_RESET_PASSWORD: '@@administrator/changePassword',
  POST_CUSTOMER_SETUP_SECURITY: '@@administrator/setupSecurity',
  POST_CUSTOMER_CONTACT_INFO_REQUEST:
    '@@customer/POST_CUSTOMER_CONTACT_INFO_REQUEST',
  POST_CUSTOMER_VERIFY_REQUEST: '@@customer/POST_CUSTOMER_VERIFY_REQUEST',
  POST_CUSTOMER_INFO_REQUEST: '@@customer/POST_CUSTOMER_TOKEN_INFO_REQUEST',
  POST_VERIFICATION_DNS: '@@customer/POST_VERIFICATION_DNS',
  GET_DOMAINS: '@@customer/GET_DOMAINS',
  POST_VERIFY_DOMAIN: '@@customer/POST_VERIFY_DOMAIN',
  DELETE_DOMAIN: '@@customer/DELETE_DOMAIN',
  POST_CUSTOMER_MFA_COMPLEXITY: '@@customer/POST_CUSTOMER_MFA_COMPLEXITY',
  POST_CUSTOMER_PIN_COMPLEXITY: '@@customer/POST_CUSTOMER_PIN_COMPLEXITY',
  POST_CUSTOMER_USER_PERMISSION: '@@customer/POST_CUSTOMER_USER_PERMISSION',
  POST_CUSTOMER_SECURITY_KEY_SETTING:
    '@@customer/POST_CUSTOMER_SECURITY_KEY_SETTING',
};

export const getCustomerInfoRequest = (data) =>
  action(CustomersActionTypes.GET_CUSTOMER_INFO_REQUEST, data);
export const postCustomerResetPassword = (data) =>
  action(CustomersActionTypes.GET_CUSTOMER_RESET_PASSWORD, data);
export const postCustomerSetupSecurity = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_SETUP_SECURITY, data);
export const postCustomerContactInfoRequest = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_CONTACT_INFO_REQUEST, data);
export const postCustomerVerifyRequest = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_VERIFY_REQUEST, data);
export const postCustomerInfoRequest = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_INFO_REQUEST, data);
export const postVerificationDnsRequest = (data) =>
  action(CustomersActionTypes.POST_VERIFICATION_DNS, data);
export const getDomainsRequest = (data) =>
  action(CustomersActionTypes.GET_DOMAINS, data);
export const postVerifyDomainRequest = (data) =>
  action(CustomersActionTypes.POST_VERIFY_DOMAIN, data);
export const deleteDomainRequest = (data) =>
  action(CustomersActionTypes.DELETE_DOMAIN, data);
export const postCustomerMFAComplexity = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_MFA_COMPLEXITY, data);
export const postCustomerPinComplexity = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_PIN_COMPLEXITY, data);
export const postCustomerUserPermission = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_USER_PERMISSION, data);
export const postCustomerSecurityKeySetting = (data) =>
  action(CustomersActionTypes.POST_CUSTOMER_SECURITY_KEY_SETTING, data);
