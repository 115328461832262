import Dispatch from './client';

const urls = {
  getSharedDevices: {
    url: `/administrator/tokens/share`,
    method: 'get',
  },
  deleteSharedDevices: {
    url: `/administrator/tokens/share/deleteMany`,
    method: 'delete',
  },
};

function api() {
  return {
    getSharedDevicesRequest: (data) =>
      Dispatch(urls.getSharedDevices, data.params, data.payload),
    deleteSharedDevices: (data) =>
      Dispatch(urls.deleteSharedDevices, {}, data.payload),
  };
}

export default api();
