// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import accessPolicyApis from 'apis/accessPolicyApi';
// actions
import { AccessPolicyActionTypes } from 'actions/accessPolicyActions';

export function* handleGet({ payload }) {
  try {
    const response = yield call(accessPolicyApis.getAccessPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePost({ payload }) {
  try {
    const response = yield call(accessPolicyApis.postAccessPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handlePutAccessPolicy({ payload }) {
  try {
    const response = yield call(accessPolicyApis.putAccessPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteAccessPolicy({ payload }) {
  try {
    const response = yield call(accessPolicyApis.deleteAccessPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetAllPolicy({ payload }) {
  try {
    const response = yield call(accessPolicyApis.getAllPolicy, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* tokenPolicySaga() {
  yield all([
    yield takeEvery(
      AccessPolicyActionTypes.GET_ACCESS_POLICY_REQUEST,
      handleGet,
    ),
    yield takeEvery(
      AccessPolicyActionTypes.POST_ACCESS_POLICY_REQUEST,
      handlePost,
    ),
    yield takeEvery(
      AccessPolicyActionTypes.PUT_ACCESS_POLICY_REQUEST,
      handlePutAccessPolicy,
    ),
    yield takeEvery(
      AccessPolicyActionTypes.DELETE_ACCESS_POLICY_REQUEST,
      handleDeleteAccessPolicy,
    ),
    yield takeEvery(AccessPolicyActionTypes.GET_ALL_POLICY, handleGetAllPolicy),
  ]);
}

export default tokenPolicySaga;
