import Dispatch from './SSO_client';

const urls = {
  activeServices: (method) => ({
    url: '/api/sp/active/',
    method: method,
  }),
  availableServices: (method) => ({
    url: '/api/spd/',
    method: method,
  }),
  serviceProviders: (method) => ({
    url: '/api/sp/',
    method: method,
  }),
  serviceConfigs: (method) => ({
    url: '/api/spc/',
    method: method,
  }),
  deleteService: () => ({
    url: '/api/sp/delete/',
    method: 'delete',
  }),
  getServiceDocs: (uid) => ({
    url: `/api/sp/doc/${uid}`,
    method: 'get',
  }),
  getScimDocs: (uid) => ({
    url: `/api/scim/doc/${uid}`,
    method: 'get',
  }),
  getAppd: () => ({
    url: `/api/appd/`,
    method: 'get',
  }),
  app: (method) => ({
    url: '/api/app/',
    method: method,
  }),
  getServiceGroups: (uid) => ({
    url: `/api/groups/${uid}/`,
    method: 'get',
  }),
};

function api() {
  return {
    activeServices: (data) =>
      Dispatch(urls.activeServices(data.method), data.params, data.payload),
    availableServices: (data) =>
      Dispatch(urls.availableServices(data.method), data.params, data.payload),
    serviceProviders: (data) =>
      Dispatch(urls.serviceProviders(data.method), data.params, data.payload),
    serviceConfigs: (data) =>
      Dispatch(urls.serviceConfigs(data.method), data.params, data.payload),
    deleteService: (data) =>
      Dispatch(urls.deleteService(), data.params, data.payload),
    getServiceDocs: (data) =>
      Dispatch(urls.getServiceDocs(data.uid), data.params, data.payload),
    getScimDocs: (data) =>
      Dispatch(urls.getScimDocs(data.uid), data.params, data.payload),
    getAppd: (data) => Dispatch(urls.getAppd(), data.params, data.payload),
    app: (data) => Dispatch(urls.app(data.method), data.params, data.payload),
    getServiceGroups: (data) =>
      Dispatch(urls.getServiceGroups(data.uid), data.params, data.payload),
  };
}

export default api();
