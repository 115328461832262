export const updateStoredObject = (userId, newKey, newValue) => {
  const cachedObject = JSON.parse(localStorage.getItem(userId));

  if (cachedObject) {
    cachedObject[newKey] = newValue;
    localStorage.setItem(userId, JSON.stringify(cachedObject));
  } else {
    localStorage.setItem(userId, JSON.stringify({ [newKey]: newValue }));
  }
};

export const isObjectStored = (userId, objectKey) => {
  const cachedObject = JSON.parse(localStorage.getItem(userId));
  return cachedObject && cachedObject[objectKey];
};

export const clearStorageKeepUsers = () => {
  localStorage.removeItem('azureAD');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('id_token');
  localStorage.removeItem('page-has-been-force-refreshed');
};

export const clearStorage = () => {
  localStorage.clear();
};
