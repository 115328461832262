import Dispatch from './client';

const urls = {
  getAllUsers: (method, id) => ({
    url: id
      ? `/administrator/tokens/${id}`
      : `/administrator/tokens${method === 'get' ? '/v2' : ''}`,
    method,
  }),
  postResendEmail: {
    url: '/administrator/tokens/sendVerify',
    method: 'post',
  },
  putActiveUser: {
    url: '/administrator/tokens/activeToken',
    method: 'put',
  },
  putDeActiveUser: {
    url: '/administrator/tokens/deActiveToken',
    method: 'put',
  },
  postUploadCsvFile: {
    url: '/administrator/tokens/uploadCsv',
    method: 'post',
  },
  deleteUploadCsvFile: {
    url: '/administrator/tokens/uploadCsv',
    method: 'delete',
  },
  postDeleteManyUsers: {
    url: '/administrator/tokens/deleteMany',
    method: 'post',
  },
  postDeactiveManyUsers: {
    url: '/administrator/tokens/deactiveMany',
    method: 'post',
  },
  postResendEmailManyUsers: {
    url: '/administrator/tokens/sendVerifyMany',
    method: 'post',
  },
  postActiveManyUsers: {
    url: '/administrator/tokens/activeMany',
    method: 'post',
  },
  putChangeTokenPolicy: {
    url: '/administrator/tokens/tokenPolicy',
    method: 'put',
  },
  postAddToThisDomain: {
    url: '/administrator/tokens/addIndividual',
    method: 'post',
  },
  postSetDefaultOBR: {
    url: '/administrator/tokens/setDefaulte',
    method: 'post',
  },
  postAssignSecurityKey: {
    url: '/administrator/tokens/assignSecurityKey',
    method: 'post',
  },
  getTemporaryAccess: (_id) => ({
    url: `/administrator/tokens/${_id}/temporaryAccess`,
    method: 'get',
  }),
  getGroupsInAddUser: {
    url: '/administrator/groups?type=local',
    method: 'get',
  },
  putChangeUserInfo: {
    url: '/administrator/tokens/userInfo/profile',
    method: 'put',
  },
  getUserInfo: (_id) => ({
    url: `/administrator/tokens/userInfo/${_id}/detail`,
    method: 'get',
  }),
  putResetPin: (_id) => ({
    method: 'put',
    url: `/administrator/tokens/securityKey/${_id}/resetPin`,
  }),
  deleteAllSecurityKeys: (_id) => ({
    method: 'delete',
    url: `/administrator/tokens/${_id}/securityKey`,
  }),
  putSuspendManyUsers: {
    url: '/administrator/tokens/suspendMany',
    method: 'put',
  },
  msTAP: (_id, method) => ({
    url: `/administrator/aad/tap/${_id}`,
    method,
  }),
  postAutoOBR: {
    url: `/administrator/tokens/azureAutoOBR`,
    method: 'post',
  },
  getAutoOBRResults: {
    url: `/administrator/tokens/azureAutoOBR`,
    method: 'get',
  },
  putSecurityDeviceName: (_id) => ({
    url: `/administrator/securityDevice/${_id}`,
    method: 'put',
  }),
  getUserSecuritykeys: (_id) => ({
    url: `/administrator/tokens/userInfo/${_id}/securitykeys/v2`,
    method: 'get',
  }),
  postAddUserSecuritykeys: {
    url: `/administrator/tokens/userInfo/securitykey`,
    method: 'post',
  },
  postAddUserGroups: {
    url: `/administrator/tokens/userInfo/group`,
    method: 'post',
  },
  deleteUserGroups: {
    url: `/administrator/tokens/userInfo/group`,
    method: 'delete',
  },
  getUserDevice: {
    url: `/administrator/tokens/userInfo/securitykeys`,
    method: 'get',
  },
  getUserSharedDevices: (_id) => ({
    url: `/administrator/securityDevice/user/${_id}/connected-devices`,
    method: 'get',
  }),
  getAllUserPassword: (id) => ({
    url: `/administrator/tokens/${id}/passwords`,
    method: 'get',
  }),
  editUserPassword: (id, passwordId) => ({
    url: `/administrator/tokens/${id}/passwords/${passwordId}`,
    method: 'put',
  }),
  addUserPassword: (id) => ({
    url: `/administrator/tokens/${id}/passwords`,
    method: 'post',
  }),
  deleteUserPassword: (id, passwordId) => ({
    url: `/administrator/tokens/${id}/passwords/${passwordId}`,
    method: 'delete',
  }),
  putLastSeenChangeLog: {
    url: '/administrator/tokens/last-seen-change-log',
    method: 'put',
  },
  getUserPasskey: {
    url: `/administrator/tokens/activity/credentials`,
    method: 'get',
  },
  getUserLoginAttempts: {
    url: `/users/login-attempts`,
    method: 'get',
  },
};

function api() {
  return {
    getAllUsers: (data) =>
      Dispatch(urls.getAllUsers('get'), data.params, data.payload),
    postNewUsers: (data) =>
      Dispatch(urls.getAllUsers('post'), data.params, data.payload),
    postResendEmail: (data) => Dispatch(urls.postResendEmail, {}, data.payload),
    deleteUser: (data) =>
      Dispatch(urls.getAllUsers('delete', data.params._id), {}, data.payload),
    putActiveUser: (data) => Dispatch(urls.putActiveUser, {}, data.payload),
    putDeActiveUser: (data) => Dispatch(urls.putDeActiveUser, {}, data.payload),
    postUploadCsvFile: (data) =>
      Dispatch(urls.postUploadCsvFile, data.params, data.payload),
    deleteUploadCsvFile: (data) =>
      Dispatch(urls.deleteUploadCsvFile, data.params, data.payload),
    postDeleteManyUsers: (data) =>
      Dispatch(urls.postDeleteManyUsers, {}, data.payload),
    postDeactiveManyUsers: (data) =>
      Dispatch(urls.postDeactiveManyUsers, {}, data.payload),
    postActiveManyUsers: (data) =>
      Dispatch(urls.postActiveManyUsers, {}, data.payload),
    postResendEmailManyUsers: (data) =>
      Dispatch(urls.postResendEmailManyUsers, {}, data.payload),
    putChangeTokenPolicy: (data) =>
      Dispatch(urls.putChangeTokenPolicy, {}, data.payload),
    postAddToThisDomain: (data) =>
      Dispatch(urls.postAddToThisDomain, {}, data.payload),
    postSetDefaultOBR: (data) =>
      Dispatch(urls.postSetDefaultOBR, {}, data.payload),
    postAssignSecurityKey: (data) =>
      Dispatch(urls.postAssignSecurityKey, {}, data.payload),
    getTemporaryAccess: (data) =>
      Dispatch(
        urls.getTemporaryAccess(data.tokenId),
        data.params,
        data.payload,
      ),
    getGroupsInAddUser: (data) =>
      Dispatch(urls.getGroupsInAddUser, data.params, data.payload),
    putChangeUserInfo: (data) =>
      Dispatch(urls.putChangeUserInfo, {}, data.payload),
    getUserInfo: (data) =>
      Dispatch(urls.getUserInfo(data.tokenId), data.params, data.payload),
    putResetPin: (data) =>
      Dispatch(urls.putResetPin(data.deviceId), data.params, data.payload),
    deleteAllSecurityKeys: (data) =>
      Dispatch(
        urls.deleteAllSecurityKeys(data.params._id),
        data.params,
        data.payload,
      ),
    putSuspendManyUsers: (data) =>
      Dispatch(urls.putSuspendManyUsers, {}, data.payload),
    getMsTAP: (data) =>
      Dispatch(urls.msTAP(data._id, 'get'), data.params, data.payload),
    putEnableMsTAP: (data) =>
      Dispatch(urls.msTAP(data._id, 'put'), {}, data.payload),
    postAutoOBR: (data) => Dispatch(urls.postAutoOBR, {}, data.payload),
    getAutoOBRResults: (data) =>
      Dispatch(urls.getAutoOBRResults, data.params, data.payload),
    putSecurityDeviceName: (data) =>
      Dispatch(urls.putSecurityDeviceName(data.params._id), {}, data.payload),

    getUserSecuritykeys: (data) =>
      Dispatch(
        urls.getUserSecuritykeys(data.userId),
        data.params,
        data.payload,
      ),
    postAddUserGroups: (data) =>
      Dispatch(urls.postAddUserGroups, {}, data.payload),
    deleteUserGroups: (data) =>
      Dispatch(urls.deleteUserGroups, {}, data.payload),
    postAddUserSecuritykeys: (data) =>
      Dispatch(urls.postAddUserSecuritykeys, {}, data.payload),
    getUserDevice: (data) =>
      Dispatch(urls.getUserDevice, data.params, data.payload),
    getUserSharedDevices: (data) =>
      Dispatch(
        urls.getUserSharedDevices(data.userId),
        data.params,
        data.payload,
      ),
    getAllUserPassword: (data) =>
      Dispatch(urls.getAllUserPassword(data.id), data.params, data.payload),
    addUserPassword: (data) =>
      Dispatch(urls.addUserPassword(data.id), data.params, data.payload),
    editUserPassword: (data) =>
      Dispatch(
        urls.editUserPassword(data.id, data.passwordId),
        data.params,
        data.payload,
      ),
    deleteUserPassword: (data) =>
      Dispatch(
        urls.deleteUserPassword(data.id, data.passwordId),
        data.params,
        data.payload,
      ),
    putLastSeenChangeLog: (data) =>
      Dispatch(urls.putLastSeenChangeLog, data.params, data.payload),
    getUserPasskey: (data) =>
      Dispatch(urls.getUserPasskey, data.params, data.payload),
    getUserLoginAttempts: (data) =>
      Dispatch(urls.getUserLoginAttempts, data.params, data.payload),
  };
}

export default api();
