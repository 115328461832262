import Dispatch from './client';

const urls = {
  trustDomains: (method, _id) => ({
    url: _id
      ? `/administrator/trustDomains/${_id}`
      : '/administrator/trustDomains',
    method: method,
  }),
};

function api() {
  return {
    postTrustDomains: (data) =>
      Dispatch(urls.trustDomains('post'), data.params, data.payload),
    deleteTrustDomains: (data) =>
      Dispatch(
        urls.trustDomains('delete', data.payload._id),
        data.params,
        data.payload,
      ),
  };
}

export default api();
