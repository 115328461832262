import Dispatch from './client';

const urls = {
  activityLog: {
    url: '/administrator/individual/logs',
    method: 'get',
  },
  information: {
    url: '/administrator/individual',
    method: 'get',
  },
  reactive: {
    url: '/administrator/individual/reactive',
    method: 'put',
  },
  postRedeemPromo: (code) => ({
    url: `/administrator/promoCode/redeem/${code}`,
    method: 'post',
  }),
  postRedeemPromoRegister: (code) => ({
    url: `/register/promoCode/redeem/${code}`,
    method: 'post',
  }),
};

function api() {
  return {
    getActivityLog: (data) =>
      Dispatch(urls.activityLog, data.params, data.payload),
    getInformation: (data) =>
      Dispatch(urls.information, data.params, data.payload),
    reactive: (data) => Dispatch(urls.reactive, data.params, data.payload),
    postRedeemPromo: (data) =>
      Dispatch(urls.postRedeemPromo(data.code), data.params, data.payload),
    postRedeemPromoRegister: (data) =>
      Dispatch(
        urls.postRedeemPromoRegister(data.code),
        data.params,
        data.payload,
      ),
  };
}

export default api();
