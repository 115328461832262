import Dispatch from './client';

const urls = {
  postCard: {
    url: `/users/payment-confirm/cards`,
    method: 'post',
  },
  deleteCards: {
    url: `/users/payment-confirm/cards`,
    method: 'delete',
  },
  getCards: {
    url: `/users/payment-confirm/cards`,
    method: 'get',
  },
  addConfirm: {
    url: `/users/payment-confirm`,
    method: 'post',
  },
  getConfirm: {
    url: `/users/payment-confirm`,
    method: 'get',
  },
};

function api() {
  return {
    addCardRequest: (data) =>
      Dispatch(urls.postCard, data.params, data.payload),
    deleteCardsRequest: (data) =>
      Dispatch(urls.deleteCards, data.params, data.payload),
    getCardsRequest: (data) =>
      Dispatch(urls.getCards, data.params, data.payload),
    addConfirmRequest: (data) =>
      Dispatch(urls.addConfirm, data.params, data.payload),
    getConfirmRequest: (data) =>
      Dispatch(urls.getConfirm, data.params, data.payload),
  };
}

export default api();
