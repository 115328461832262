import { action } from 'typesafe-actions';

export const DevicesActionTypes = {
  GET_FCP_CONFIG_REQUEST: '@@fcpDevices/GET_FCP_CONFIG_REQUEST',
  GET_FCP_PCS_REQUEST: '@@fcpDevices/GET_FCP_PCS_REQUEST',
  DELETE_FCP_PC_REQUEST: '@@fcpDevices/DELETE_FCP_PC_REQUEST',
  PUT_WINDOWS_LOGIN_FOR_FCP_REQUEST:
    '@@fcpDevices/PUT_WINDOWS_LOGIN_FOR_FCP_REQUEST',
  POST_FCP_CREDENTIAL_REQUEST: '@@fcpDevices/POST_FCP_CREDENTIAL_REQUEST',
};

export const getFCPconfigRequest = (data) =>
  action(DevicesActionTypes.GET_FCP_CONFIG_REQUEST, data);
export const getFCPPCsRequest = (data) =>
  action(DevicesActionTypes.GET_FCP_PCS_REQUEST, data);
export const deleteFCPPCRequest = (data) =>
  action(DevicesActionTypes.DELETE_FCP_PC_REQUEST, data);
export const putWindowsLoginforFCPRequest = (data) =>
  action(DevicesActionTypes.PUT_WINDOWS_LOGIN_FOR_FCP_REQUEST, data);
export const postFCPCredentialRequest = (data) =>
  action(DevicesActionTypes.POST_FCP_CREDENTIAL_REQUEST, data);
