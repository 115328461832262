import { all, call, takeEvery } from 'redux-saga/effects';
import statisticsApis from 'apis/statisticsApis';
import { statisticsActionTypes } from 'actions/statisticsActions';

export function* handleGetStatistics({ payload }) {
  try {
    const response = yield call(statisticsApis.getStatistics, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetTopWorkspace({ payload }) {
  try {
    const response = yield call(statisticsApis.getTopWorkspace, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

function* workspaceSaga() {
  yield all([
    yield takeEvery(statisticsActionTypes.GET_STATISTICS, handleGetStatistics),
    yield takeEvery(
      statisticsActionTypes.GET_TOP_WORKSPACE,
      handleGetTopWorkspace,
    ),
  ]);
}

export default workspaceSaga;
