import { action } from 'typesafe-actions';

export const SharedDevicesActionTypes = {
  GET_SHARED_DEVICES_REQUEST: '@@sharedDevices/GET_SHARED_DEVICES_REQUEST',
  DELETE_SHARED_DEVICES_REQUEST:
    '@@sharedDevices/DELETE_SHARED_DEVICES_REQUEST',
};

export const getSharedDevicesRequest = (data) =>
  action(SharedDevicesActionTypes.GET_SHARED_DEVICES_REQUEST, data);

export const deleteSharedDevicesRequest = (data) =>
  action(SharedDevicesActionTypes.DELETE_SHARED_DEVICES_REQUEST, data);
