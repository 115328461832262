import { action } from 'typesafe-actions';

export const TrustDomainsActionTypes = {
  POST_TRUST_DOMAINS_REQUEST: '@@trustDomains/POST_TRUST_DOMAINS_REQUEST',
  DELETE_TRUST_DOMAINS_REQUEST: '@@trustDomains/DELETE_TRUST_DOMAINS_REQUEST',
  // POST_TOKEN_POLICY_REQUEST: '@@trustDomains/POST_TOKEN_POLICY_REQUEST',
  // PUT_DEFAULT_TOKEN_POLICY_REQUEST: '@@trustDomains/PUT_DEFAULT_TOKEN_POLICY_REQUEST',
  // GET_DEFAULT_TOKEN_POLICY_REQUEST: '@@trustDomains/GET_DEFAULT_TOKEN_POLICY_REQUEST'
};

export const postTrustDomainsRequest = (data) =>
  action(TrustDomainsActionTypes.POST_TRUST_DOMAINS_REQUEST, data);
export const deleteTrustDomainsRequest = (data) =>
  action(TrustDomainsActionTypes.DELETE_TRUST_DOMAINS_REQUEST, data);
